import { State } from '../../../store/State';
import { CurrentTeam } from '../../team/CurrentTeam';
import { GetTimeInStatus } from '../utils/GetTimeInStatus';
import { CurrentSprint } from '../CurrentSprint';
import { TimeInStatus as ITimeInStatus } from '../../../interfaces/TimeInStatus';

export const TimeInStatus = (state: State): ITimeInStatus[] => {
    const sprintData = CurrentSprint(state);
    const currentTeam = CurrentTeam(state);
    return GetTimeInStatus(sprintData, currentTeam);
};
