import { State } from '../../store/State';
import { Nullable, TimeInStatus } from '@aviture/interfaces';
import { FilteredSprints } from '../sprint/FilteredSprints';

interface SprintTimeInStatus {
    name: string;
    metric?: TimeInStatus;
}

export const AverageTimeInStatus = (state: State): Nullable<SprintTimeInStatus[]> => {
    const sprints = FilteredSprints(state);

    if (sprints && sprints.length) {
        return sprints.map((sprint) => {
            return {
                name: sprint.name,
                metric: sprint.metrics.predictability.timeInStatus,
            };
        });
    }

    return null;
};
