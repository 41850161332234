import { State } from '../../../../store/State';
import { Nullable } from '@aviture/interfaces';
import { ChartData } from '../../../../interfaces/ChartData';
import { CurrentSprint } from '../../CurrentSprint';
import { DefectSeverityComparator } from '../../../../comparators/DefectSeverityComparator';

export const CreatedDefectsBreakdownSparkChartData = (state: State): Nullable<ChartData> => {
    const sprintData = CurrentSprint(state);

    if (sprintData === null) {
        return null;
    }

    const newDefectsThisSprint = sprintData.metrics.quality.newDefectsThisSprint;

    const metric: ChartData = [['Severity', 'Count']];
    Object.keys(newDefectsThisSprint)
        .sort(DefectSeverityComparator)
        .forEach((severity) => {
            metric.push([severity, newDefectsThisSprint[severity as 'lowest' | 'low' | 'medium' | 'high' | 'urgent']]);
        });

    return metric;
};
