import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { AppReady } from '../selectors/ui/AppReady';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useGlobal from '../store';
import { Nullable } from '@aviture/interfaces';
import TextField from '@mui/material/TextField';

interface Props {
    open: boolean;
    startDate: Nullable<string>;
    endDate: Nullable<string>;
    onClose: () => void;
    onSubmit: (startDate: string, endDate: string) => void;
}

const CustomFilterDialog: React.FC<Props> = (props) => {
    const [state] = useGlobal();
    const [startDate, setStartDate] = useState<Nullable<string>>(props.startDate);
    const [endDate, setEndDate] = useState<Nullable<string>>(props.endDate);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const hideDialog = () => {
        props.onClose();
        setStartDate(null);
        setEndDate(null);
    };

    const filterDashboard = async () => {
        props.onSubmit(startDate as string, endDate as string);
    };

    const updateStartDate = (date: unknown) => {
        if (date) {
            setStartDate(date as unknown as string);
        } else {
            setStartDate(null);
        }
    };

    const updateEndDate = (date: unknown) => {
        if (date) {
            setEndDate(date as unknown as string);
        } else {
            setEndDate(null);
        }
    };

    useEffect(() => {
        setStartDate(props.startDate);
        setEndDate(props.endDate);
    }, [props.startDate, props.endDate]);

    return (
        <React.Fragment>
            {AppReady(state) ? (
                <Dialog
                    fullScreen={fullScreen}
                    open={props.open}
                    onClose={hideDialog}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onBackdropClick={() => {}}
                    disableEscapeKeyDown
                >
                    <DialogTitle id="custom-filter-title">Custom Filter</DialogTitle>
                    <DialogContent>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                showToolbar={false}
                                mask="yyyy/MM/dd"
                                label="Start Date"
                                value={startDate}
                                onChange={updateStartDate}
                                renderInput={(params) => (
                                    <TextField required sx={{ marginRight: theme.spacing(2) }} {...params} />
                                )}
                            />
                            <DatePicker
                                showToolbar={false}
                                mask="yyyy/MM/dd"
                                label="End Date"
                                value={endDate}
                                onChange={updateEndDate}
                                renderInput={(params) => <TextField required {...params} />}
                            />
                        </LocalizationProvider>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={hideDialog} color="secondary" variant="contained">
                            Cancel
                        </Button>
                        <Button
                            onClick={filterDashboard}
                            color="primary"
                            variant="contained"
                            disabled={!startDate || !endDate}
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            ) : undefined}
        </React.Fragment>
    );
};

export default CustomFilterDialog;
