import { Nullable, Sprint } from '@aviture/interfaces';

export const GetActualVelocity = (sprintData: Nullable<Sprint>): number | undefined => {
    if (sprintData === null) {
        return;
    }

    const velocity = sprintData.metrics?.productivity.velocity;
    if (velocity) {
        return velocity.actual;
    }

    return;
};
