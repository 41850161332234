import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface Props {
    open: boolean;
    onClose: () => void;
    onSubmit: (sprintId: string) => void;
    sprintId: string;
    sprintName: string;
}

const DeleteSprintDialog: React.FC<Props> = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const hideDialog = () => {
        props.onClose();
    };

    const removeSprint = async () => {
        props.onClose();
        props.onSubmit(props.sprintId);
    };

    return (
        <React.Fragment>
            <Dialog
                fullScreen={fullScreen}
                open={props.open}
                onClose={hideDialog}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onBackdropClick={() => {}}
                disableEscapeKeyDown
            >
                <DialogTitle id="remove-sprint-title">Delete Sprint?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="remove-sprint-text">
                        Are you sure you want to delete the sprint &quot;{props.sprintName}&quot;?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={hideDialog} color="secondary" variant="contained">
                        No, Cancel
                    </Button>
                    <Button onClick={removeSprint} color="primary" variant="contained">
                        Yes, Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default DeleteSprintDialog;
