import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { AmplifyProvider } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import config from './config';

import { Theme } from '@aws-amplify/ui-react';
import { createTheme } from '@aws-amplify/ui';

import '@aws-amplify/ui-react/styles.css';

Amplify.configure({
    Auth: config.auth,
    API: config.api,
});

const amplifyTheme: Theme = createTheme({
    name: 'metrics-theme',
    tokens: {
        components: {
            button: {
                primary: {
                    backgroundColor: {
                        value: 'rgba(0, 39, 54, 1)'
                    },
                    color: {
                        value: 'white',
                    },
                },
                link: {
                    backgroundColor: {
                        value: 'white'
                    },
                    color: {
                        value: 'rgba(0, 39, 54, 1)'
                    },
                    _hover: {
                        backgroundColor: {
                            value: 'white'
                        },
                        color: {
                            value: 'rgba(0, 39, 54, 1)'
                        }
                    },
                    _focus: {
                        backgroundColor: {
                            value: 'white'
                        },
                        color: {
                            value: 'rgba(0, 39, 54, 1)'
                        }
                    }
                }
            },
            authenticator: {
                modal: {
                    backgroundColor: {
                        value: '#eaebec'
                    }
                }
            }
        },
        colors: {
            background: {
                primary: {
                    value: 'white',
                },
            },
            font: {
                primary: {
                    value: 'black',
                },
            },
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <AmplifyProvider theme={amplifyTheme}>
            <App />
        </AmplifyProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
