import { State } from '../../store/State';
import { CurrentUser } from './CurrentUser';

export const CurrentUserIsAdmin = (state: State): boolean => {
    const currentUser = CurrentUser(state);
    if (currentUser !== null) {
        return !!(currentUser?.groups && currentUser.groups.indexOf('admin') >= 0);
    }

    return false;
};
