import React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Nullable } from '@aviture/interfaces';
import { SxProps, Theme, useTheme } from '@mui/material/styles';

enum Months {
    JANUARY = 1,
    FEBRUARY = 2,
    MARCH = 3,
    APRIL = 4,
    MAY = 5,
    JUNE = 6,
    JULY = 7,
    AUGUST = 8,
    SEPTEMBER = 9,
    OCTOBER = 10,
    NOVEMBER = 11,
    DECEMBER = 12,
}

interface Props {
    month: Nullable<Months>;
    year: Nullable<number>;
    yearRange: Array<number>;
    onChangeMonth: (month: Months) => void;
    onChangeYear: (year: number) => void;
    sx?: SxProps<Theme>;
}

const MonthYearPicker: React.FC<Props> = (props: Props) => {
    const theme = useTheme();

    const updateMonth = async (event: any) => {
        await props.onChangeMonth(Number.parseInt(event.target.value, 10));
    };

    const updateYear = async (event: any) => {
        await props.onChangeYear(Number.parseInt(event.target.value, 10));
    };

    const yearMenuItems = props.yearRange
        ? props.yearRange.map((year) => (
              <MenuItem value={year} key={year}>
                  {year}
              </MenuItem>
          ))
        : [];

    const nowTime = new Date().getTime();

    return (
        <Box sx={props.sx}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Box>
                    <FormControl variant="outlined">
                        <InputLabel id={`month-label-${nowTime}`}>Month</InputLabel>
                        <Select
                            value={props.month ? props.month : ''}
                            onChange={updateMonth}
                            label="Month"
                            sx={{
                                marginRight: theme.spacing(2),
                                minWidth: '150px',
                            }}
                            id={`month-selector-${nowTime}`}
                        >
                            <MenuItem value={Months.JANUARY} key={Months.JANUARY}>
                                January
                            </MenuItem>
                            <MenuItem value={Months.FEBRUARY} key={Months.FEBRUARY}>
                                February
                            </MenuItem>
                            <MenuItem value={Months.MARCH} key={Months.MARCH}>
                                March
                            </MenuItem>
                            <MenuItem value={Months.APRIL} key={Months.APRIL}>
                                April
                            </MenuItem>
                            <MenuItem value={Months.MAY} key={Months.MAY}>
                                May
                            </MenuItem>
                            <MenuItem value={Months.JUNE} key={Months.JUNE}>
                                June
                            </MenuItem>
                            <MenuItem value={Months.JULY} key={Months.JULY}>
                                July
                            </MenuItem>
                            <MenuItem value={Months.AUGUST} key={Months.AUGUST}>
                                August
                            </MenuItem>
                            <MenuItem value={Months.SEPTEMBER} key={Months.SEPTEMBER}>
                                September
                            </MenuItem>
                            <MenuItem value={Months.OCTOBER} key={Months.OCTOBER}>
                                October
                            </MenuItem>
                            <MenuItem value={Months.NOVEMBER} key={Months.NOVEMBER}>
                                November
                            </MenuItem>
                            <MenuItem value={Months.DECEMBER} key={Months.DECEMBER}>
                                December
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl variant="outlined">
                        <InputLabel id={`year-label-${nowTime}`}>Year</InputLabel>
                        <Select
                            value={props.year ? props.year : ''}
                            onChange={updateYear}
                            label="Year"
                            id={`year-selector-${nowTime}`}
                            sx={{
                                minWidth: '150px',
                            }}
                        >
                            {yearMenuItems}
                        </Select>
                    </FormControl>
                </Box>
            </Box>
        </Box>
    );
};

export default MonthYearPicker;
