import { Nullable, Sprint } from '@aviture/interfaces';

export const GetCreatedDefectsCount = (sprintData: Nullable<Sprint>): number => {
    if (sprintData === null) {
        return 0;
    }

    const newDefectsThisSprint = sprintData.metrics.quality.newDefectsThisSprint;
    if (newDefectsThisSprint) {
        let count = 0;
        Object.keys(newDefectsThisSprint).forEach((severity: string) => {
            count += (newDefectsThisSprint[severity as 'lowest' | 'low' | 'medium' | 'high' | 'urgent']);
        });

        return count;
    }

    return 0;
};
