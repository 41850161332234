import { Store } from 'use-global-hook';
import { State } from '../store/State';
import { API } from 'aws-amplify';
import { ClearAlert } from './ClearAlert';
import { CurrentTeam } from '../selectors/team/CurrentTeam';
import { FireAlert } from './FireAlert';
import { SetAppLoading } from './SetAppLoading';
import { Sprint } from '@aviture/interfaces';
import { SprintViewModel } from '../interfaces/SprintViewModel';
import { UpdateTeamData } from './UpdateTeamData';
import { generateSprint } from '../factories/SprintFactory';

export const EditSprint = async (store: Store<State, unknown>, data: SprintViewModel): Promise<void> => {
    const currentTeam = CurrentTeam(store.state);

    if (currentTeam !== null && data !== null) {
        const sprint = generateSprint(data);

        if (currentTeam.sprints) {
            currentTeam.sprints = currentTeam.sprints.filter((sprint) => {
                return sprint.id !== data.id;
            });
            currentTeam.sprints.push(sprint);
        } else {
            currentTeam.sprints = new Array<Sprint>();
            currentTeam.sprints.push(sprint);
        }

        const init = {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            body: currentTeam,
        };

        SetAppLoading(store, true);
        const response = await API.put('JiraMetricsApi', '/teams/updateTeam', init);
        UpdateTeamData(store, response.team);
        SetAppLoading(store, false);
        FireAlert(store, 'Sprint updated successfully.', 'success');
        setTimeout(() => {
            ClearAlert(store);
        }, 3000);
    }
};
