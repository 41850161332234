import { State } from '../../../../store/State';
import { Nullable } from '@aviture/interfaces';
import { ChartData } from '../../../../interfaces/ChartData';
import { CurrentSprint } from '../../CurrentSprint';
import { DefectSeverityComparator } from '../../../../comparators/DefectSeverityComparator';

export const ResolvedDefectsBreakdownSparkChartData = (state: State): Nullable<ChartData> => {
    const sprintData = CurrentSprint(state);

    if (sprintData === null) {
        return null;
    }

    const closedDefectsThisSprint = sprintData.metrics.quality.closedDefectsThisSprint;

    if (closedDefectsThisSprint) {
        const metric: ChartData = [['Severity', 'Count']];
        Object.keys(closedDefectsThisSprint)
            .sort(DefectSeverityComparator)
            .forEach((severity) => {
                metric.push([severity, closedDefectsThisSprint[severity as 'lowest' | 'low' | 'medium' | 'high' | 'urgent']]);
            });

        return metric;
    }

    return null;
};
