import { FeatureToggle, Metadata, Nullable } from '@aviture/interfaces';

export const GetFeatureTogglesByCategory = (metadatas: Nullable<Metadata[]>, category: string): FeatureToggle[] => {
    if (metadatas !== null) {
        const featureToggles = metadatas.find((metadata) => {
            return metadata.id === 'feature-toggles';
        });

        if (featureToggles && featureToggles.value[category]) {
            return featureToggles.value[category] as FeatureToggle[];
        }
    }

    return [];
};
