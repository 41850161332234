import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import TeamMemberDialog from './TeamMemberDialog';
import { TeamMemberViewModel } from '../interfaces/TeamMemberViewModel';

interface Props {
    onClick: () => void;
    teamMember: TeamMemberViewModel;
}

const UpdateTeamMemberMenuItem: React.FC<Props> = (props) => {
    const [showDialog, setShowDialog] = useState<boolean>(false);

    const showUpdateTeamMemberDialog = () => {
        props.onClick();
        setShowDialog(true);
    };

    const hideUpdateTeamMemberDialog = () => {
        setShowDialog(false);
    };

    return (
        <React.Fragment>
            <React.Fragment>
                <MenuItem onClick={showUpdateTeamMemberDialog}>Update</MenuItem>
                <TeamMemberDialog
                    open={showDialog}
                    onClose={hideUpdateTeamMemberDialog}
                    teamMember={props.teamMember}
                />
            </React.Fragment>
        </React.Fragment>
    );
};

export default UpdateTeamMemberMenuItem;
