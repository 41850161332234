import React, { useState, useRef } from 'react';
import useGlobal from '../../store';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CreateEmployeeForm } from '../../selectors/employee/CreateEmployeeForm';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import PageContainer from './PageContainer';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

const CreateEmployeePage: React.FC = () => {
    const theme = useTheme();
    const [state, actions] = useGlobal();
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState<string>();
    const [firstNameValid, setFirstNameValid] = useState<boolean>();
    const [lastName, setLastName] = useState<string>();
    const [lastNameValid, setLastNameValid] = useState<boolean>();
    const [dirty, setDirty] = useState<boolean>(false);

    const firstNameInput = useRef<HTMLDivElement>(null);

    const handleFirstNameChange = (event: any) => {
        const value = event.target.value;
        setFirstName(value);
        setDirty(true);
        setFirstNameValid(value.trim().length > 0);
    };

    const handleLastNameChange = (event: any) => {
        const value = event.target.value;
        setLastName(value);
        setDirty(true);
        setLastNameValid(value.trim().length > 0);
    };

    const clearForm = () => {
        setFirstName('');
        setFirstNameValid(false);
        setLastName('');
        setLastNameValid(false);
        setDirty(false);
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (firstNameValid && lastNameValid) {
            await actions.CreateEmployee({
                firstName,
                lastName,
                active: true,
            });

            if (CreateEmployeeForm(state).response) {
                actions.FireAlert('Employee created successfully.', 'success');
                clearForm();
                if (firstNameInput !== null && firstNameInput.current !== null) {
                    firstNameInput.current.focus();
                }
                actions.ClearEmployees();
                await actions.GetAllEmployees();
            } else {
                actions.FireAlert('An error occurred attempting to create an employee.', 'error');
            }
        }
    };

    const handleCancel = () => {
        clearForm();
        navigate('/admin/employees');
    };

    return (
        <PageContainer activePage="employees">
            <Typography
                variant="h6"
                sx={{
                    marginBottom: theme.spacing(3),
                }}
            >
                Create Employee
            </Typography>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            id="firstName"
                            label="First Name"
                            value={firstName}
                            variant="outlined"
                            required
                            error={dirty && !firstNameValid}
                            onChange={handleFirstNameChange}
                            autoFocus={true}
                            inputRef={firstNameInput}
                            inputProps={{ maxLength: 50 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="lastName"
                            label="Last Name"
                            value={lastName}
                            variant="outlined"
                            required
                            error={dirty && !lastNameValid}
                            onChange={handleLastNameChange}
                            inputProps={{ maxLength: 50 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Button onClick={handleCancel} color="secondary" variant="contained">
                                Cancel
                            </Button>
                            <Button
                                onClick={handleSubmit}
                                color="primary"
                                variant="contained"
                                type="submit"
                                disabled={!firstNameValid || !lastNameValid}
                                sx={{
                                    marginLeft: theme.spacing(3),
                                }}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </PageContainer>
    );
};

export default CreateEmployeePage;
