import { Store } from 'use-global-hook';
import { State } from '../store/State';

export const SetCurrentSprintId = (store: Store<State, unknown>, sprintId: string): void => {
    if (store.state.currentSprintId !== sprintId) {
        store.setState({
            ...store.state,
            currentSprintId: sprintId,
        });
    }
};
