import React from 'react';
import Box from '@mui/material/Box';
import { Chart } from 'react-google-charts';
import { ChartData } from '../interfaces/ChartData';
import { Nullable } from '@aviture/interfaces';
import ChartContainer from '../components/ChartContainer';
import RectangularChartLoader from '../components/RectangularChartLoader';
import { FilterInfo } from '../store/State';

interface IProps {
    data: Nullable<ChartData>;
    filter: FilterInfo;
}

const VelocityReport: React.FC<IProps> = (props) => {
    return (
        <ChartContainer
            flush
            header={`Velocity Report (${props.filter.label})`}
            loading={false}
            size="large"
            loader={<RectangularChartLoader />}
        >
            {props.data && props.data.length ? (
                <Box
                    sx={{
                        textAlign: 'center',
                        width: '100%',
                    }}
                >
                    <Chart
                        height={'400px'}
                        chartType="ComboChart"
                        loader={<Box>Loading...</Box>}
                        data={props.data}
                        options={{
                            colors: ['#4285f4', 'red'],
                            legend: {
                                alignment: 'center',
                                position: 'top',
                            },
                            vAxis: {
                                title: 'Points',
                                logScale: false,
                            },
                            hAxis: {
                                title: 'Sprint',
                            },
                            seriesType: 'bars',
                            series: {
                                1: {
                                    type: 'line',
                                },
                            },
                        }}
                        rootProps={{
                            'data-testid': 'velocity-report',
                        }}
                    />
                </Box>
            ) : (
                <Box>No data</Box>
            )}
        </ChartContainer>
    );
};

export default VelocityReport;
