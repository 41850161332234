import { Nullable, Team } from '@aviture/interfaces';
import { State } from '../../store/State';
import { Teams } from '../team/Teams';

export const TeamsWithFilteredSprintsByMonthAndYear = (
    state: State,
    month: Nullable<number>,
    year: Nullable<number>
): Team[] => {
    const allTeams = Teams(state);
    const now = new Date();

    if (allTeams === null || month === null || year === null) {
        return [];
    } else if (month < 1 || month > 12) {
        console.log(`Invalid month detected: ${month}.  Expecting 1 - 12.`);
        return [];
    } else if (year < 2019 || year > now.getFullYear()) {
        console.log(`Invalid year detected:  ${year}.  Expecting 2019 - ${now.getFullYear()}`);
        return [];
    } else {
        return allTeams.map((team) => {
            const teamClone = Object.assign({}, team);
            if (teamClone.sprints && teamClone.sprints.length > 0) {
                teamClone.sprints = teamClone.sprints.filter((sprint) => {
                    const endDate = new Date(sprint.endDate);
                    const endDateMonth = endDate.getMonth();
                    const endDateYear = endDate.getFullYear();
        
                    /** Note we're adjusting month from 1-12 scale to 0-11 scale here */
                    return endDateMonth === month - 1 && endDateYear === year;
                });
            } else {
                teamClone.sprints = [];
            }

            return teamClone;
        });
    }
};
