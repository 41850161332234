import React from 'react';
import Box from '@mui/material/Box';
import SprintTrend from './SprintTrend';
import Typography from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/material/styles';

interface Props {
    key?: string;
    primaryLabel: string;
    secondaryLabel?: string;
    metric?: string | number;
    unit?: string; // Inline unit annotation to support things like % for numeric metrics
    subtitle?: string; // Appears below metric
    trend?: {
        previous?: number;
        invert?: boolean;
        invertColorOnly?: boolean;
    };
    sx?: SxProps<Theme>;
}

const SingleValueKpi: React.FC<Props> = (props) => {
    return (
        <Box sx={props.sx} key={props.key}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    minWidth: '300px',
                }}
            >
                <Typography variant="button">{props.primaryLabel}</Typography>
                {props.secondaryLabel ? <Typography variant="button">{props.secondaryLabel}</Typography> : null}
                <Typography variant="h3">
                    {props.metric === null || props.metric === undefined
                        ? 'N/A'
                        : `${props.metric}${props.unit ? `${props.unit}` : ''}`}
                </Typography>
                {props.subtitle ? <Typography variant="button">{props.subtitle}</Typography> : null}
                {props.trend ? (
                    <SprintTrend
                        current={props.metric as number}
                        previous={props.trend.previous}
                        invert={props.trend.invert}
                        invertColorOnly={props.trend.invertColorOnly}
                    />
                ) : null}
            </Box>
        </Box>
    );
};

export default SingleValueKpi;
