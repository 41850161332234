import React from 'react';
import Box from '@mui/material/Box';
import { Chart } from 'react-google-charts';
import { ChartData } from '../interfaces/ChartData';
import { Nullable } from '@aviture/interfaces';
import ChartContainer from '../components/ChartContainer';
import RectangularChartLoader from '../components/RectangularChartLoader';

interface IProps {
    data: Nullable<ChartData>;
}

const OpenDefectsBySeverity: React.FC<IProps> = (props) => {
    return (
        <ChartContainer
            flush
            header={'Open Defects By Severity'}
            loading={false}
            size="large"
            loader={<RectangularChartLoader />}
        >
            {props.data && props.data.length ? (
                <Box
                    sx={{
                        textAlign: 'center',
                        width: '100%',
                    }}
                >
                    <Chart
                        height={'400px'}
                        chartType="ColumnChart"
                        loader={<Box>Loading...</Box>}
                        data={props.data}
                        options={{
                            colors: ['#4285f4', '#34a853', '#fbbc04', '#ff6d01', '#ea4335'],
                            legend: {
                                alignment: 'center',
                                position: 'top',
                                maxLines: 4,
                            },
                            vAxis: {
                                title: 'Count',
                                logScale: false,
                            },
                            hAxis: {
                                textPosition: 'none',
                            },
                        }}
                        rootProps={{
                            'data-testid': 'open-defects-by-severity',
                        }}
                    />
                </Box>
            ) : (
                <Box>No data</Box>
            )}
        </ChartContainer>
    );
};

export default OpenDefectsBySeverity;
