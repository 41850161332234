import React, { useState } from 'react';
import { EmployeesNotOnCurrentTeam } from '../selectors/employee/EmployeesNotOnCurrentTeam';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { TeamMemberViewModel } from '../interfaces/TeamMemberViewModel';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useGlobal from '../store';

interface Props {
    open: boolean;
    onClose: () => void;
    teamMember?: TeamMemberViewModel;
}

const TeamMemberDialog: React.FC<Props> = (props) => {
    const [state, actions] = useGlobal();
    const [employeeId, setEmployeeId] = useState<string>(props.teamMember ? props.teamMember.id : '');
    const [role, setRole] = useState<string>(props.teamMember ? props.teamMember.role : 'Developer');
    const [allocation, setAllocation] = useState<string>(props.teamMember ? props.teamMember.allocation : 'Full Time');
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleSubmit = async () => {
        props.onClose();

        if (props.teamMember) {
            await actions.UpdateTeamMember({
                employeeId,
                role,
                allocation,
            });
        } else {
            await actions.AddTeamMember({
                employeeId,
                role,
                allocation,
            });
            clearState();
        }
    };

    const clearState = () => {
        setEmployeeId(props.teamMember ? props.teamMember.id : '');
        setRole(props.teamMember ? props.teamMember.role : 'Developer');
        setAllocation(props.teamMember ? props.teamMember.allocation : 'Full Time');
    };

    const hideDialog = () => {
        clearState();
        props.onClose();
    };

    const handleEmployeeIdChange = (event: any) => {
        setEmployeeId(event.target.value as string);
    };

    const handleRoleChange = (event: any) => {
        setRole(event.target.value as string);
    };

    const handleAllocationChange = (event: any) => {
        setAllocation(event.target.value as string);
    };

    const allEmployees = EmployeesNotOnCurrentTeam(state);
    const employeeMenuItems =
        allEmployees === null
            ? null
            : allEmployees.map((employee) => (
                  <MenuItem
                      value={employee.id}
                      key={employee.id}
                      selected={employee.id === employeeId}
                  >{`${employee.firstName} ${employee.lastName}`}</MenuItem>
              ));

    const now = new Date().valueOf();
    return (
        <Dialog
            fullScreen={fullScreen}
            open={props.open}
            onClose={hideDialog}
            maxWidth="md"
            fullWidth={true}
            scroll="paper"
            onBackdropClick={() => {return;}}
            disableEscapeKeyDown
        >
            <DialogTitle id="team-member-dialog-title">
                {props.teamMember ? 'Update Team Member' : 'Add Team Member'}
            </DialogTitle>
            <DialogContent sx={{
                overflow: 'hidden',
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <FormControl sx={{
                        minWidth: 120,
                        marginBottom: theme.spacing(2),
                    }} required>
                        {props.teamMember ? (
                            <TextField
                                disabled
                                id={`employee-textfield-${now}`}
                                label="Employee"
                                defaultValue={props.teamMember.name}
                            />
                        ) : (
                            <React.Fragment>
                                <InputLabel id={`employee-select-label-${now}`}>Employee</InputLabel>
                                <Select
                                    labelId={`employee-select-label-${now}`}
                                    id={`employee-select-${now}`}
                                    value={employeeId}
                                    onChange={handleEmployeeIdChange}
                                >
                                    {employeeMenuItems}
                                </Select>
                            </React.Fragment>
                        )}
                    </FormControl>
                    <FormControl sx={{
                        minWidth: 120,
                        marginBottom: theme.spacing(2),
                    }} required>
                        <InputLabel id="role-select-label">Role</InputLabel>
                        <Select labelId="role-select-label" id="role-select" value={role} onChange={handleRoleChange}>
                            <MenuItem value="Developer">Developer</MenuItem>
                            <MenuItem value="Product Owner">Product Owner</MenuItem>
                            <MenuItem value="Project Manager">Project Manager</MenuItem>
                            <MenuItem value="Quality Assurance">Quality Assurance</MenuItem>
                            <MenuItem value="Scrum Master">Scrum Master</MenuItem>
                            <MenuItem value="Specialist">Specialist</MenuItem>
                            <MenuItem value="Technical Lead">Technical Lead</MenuItem>
                            <MenuItem value="User Experience">User Experience</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{
                        minWidth: 120,
                        marginBottom: theme.spacing(2),
                    }} required>
                        <InputLabel id="allocation-select-label">Allocation</InputLabel>
                        <Select
                            labelId="allocation-select-label"
                            id="allocation-select"
                            value={allocation}
                            onChange={handleAllocationChange}
                        >
                            <MenuItem value="Full Time">Full Time</MenuItem>
                            <MenuItem value="Part Time">Part Time</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={hideDialog} color="secondary" variant="contained">
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                    disabled={employeeId === null || employeeId.length === 0 || role === null || allocation === null}
                >
                    {props.teamMember ? 'Update' : 'Add'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TeamMemberDialog;
