import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useGlobal from '../../store';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import PageContainer from './PageContainer';
import Grid from '@mui/material/Grid';
import { CurrentDashboardFilter } from '../../selectors/ui/CurrentDashboardFilter';
import { DashboardReady } from '../../selectors/ui/DashboardReady';
import DashboardFilter from '../../components/DashboardFilter';
import OpenDefectsBySeverity from '../../metrics/OpenDefectsBySeverity';
import DefectsBySprint from '../../metrics/DefectsBySprint';
import DefectBuildUp from '../../metrics/DefectBuildUp';
import CreatedVsResolvedDefectsBySprint from '../../metrics/CreatedVsResolvedDefectsBySprint';
import { CreatedVsResolvedDefectsBySprintChartData } from '../../selectors/quality/chart/CreatedVsResolvedDefectsBySprintChartData';
import { DefectBuildUpChartData } from '../../selectors/quality/chart/DefectBuildUpChartData';
import { DefectsBySprintChartData } from '../../selectors/quality/chart/DefectsBySprintChartData';
import { OpenDefectsBySeverityChartData } from '../../selectors/quality/chart/OpenDefectsBySeverityChartData';

const QualityPage: React.FC = () => {
    const theme = useTheme();
    const { teamId } = useParams();
    const [state, actions] = useGlobal();

    useEffect(() => {
        const loadData = async () => {
            if (teamId) {
                actions.SetCurrentTeam(teamId);
            } else {
                actions.SetFirstTeamAsCurrent();
            }
            actions.SetDashboardReady(true);
        };

        loadData();
    }, [actions, state.alwaysNull, teamId]);

    return (
        <PageContainer activePage="quality">
            <Box>
                {DashboardReady(state) ? (
                    <React.Fragment>
                        <Box sx={{
                             marginTop: theme.spacing(2),
                             marginBottom: theme.spacing(3),
                             display: 'flex',
                             justifyContent: 'space-between',
                        }}>
                            <DashboardFilter />
                        </Box>
                        <Grid container spacing={3} sx={{
                            paddingBottom: theme.spacing(2),
                        }}>
                            <Grid item xs={12} md={6} xl={4}>
                                <OpenDefectsBySeverity data={OpenDefectsBySeverityChartData(state)} />
                            </Grid>
                            <Grid item xs={12} md={6} xl={4}>
                                <DefectsBySprint
                                    data={DefectsBySprintChartData(state)}
                                    filter={CurrentDashboardFilter(state)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} xl={4}>
                                <CreatedVsResolvedDefectsBySprint
                                    data={CreatedVsResolvedDefectsBySprintChartData(state)}
                                    filter={CurrentDashboardFilter(state)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} xl={4}>
                                <DefectBuildUp
                                    data={DefectBuildUpChartData(state)}
                                    filter={CurrentDashboardFilter(state)}
                                />
                            </Grid>
                        </Grid>
                    </React.Fragment>
                ) : (
                    <div>Loading...</div>
                )}
            </Box>
        </PageContainer>
    );
};

export default QualityPage;
