import { State } from '../../store/State';
import { AllSprints } from './AllSprints';
import { Nullable, Sprint } from '@aviture/interfaces';
import { CurrentDashboardFilter } from '../ui/CurrentDashboardFilter';
import isWithinInterval from 'date-fns/isWithinInterval';

export const FilteredSprints = (state: State): Nullable<Sprint[]> => {
    const allSprints = AllSprints(state);
    const filter = CurrentDashboardFilter(state);

    if (allSprints && allSprints.length) {
        if (filter.startDate && filter.endDate) {
            const filteredSprints: Sprint[] = [];
            const filterStartDate = new Date(filter.startDate);
            const filterEndDate = new Date(filter.endDate);

            allSprints.forEach((sprint) => {
                if (
                    isWithinInterval(new Date(sprint.endDate), {
                        start: filterStartDate,
                        end: filterEndDate,
                    })
                ) {
                    filteredSprints.push(sprint);
                }
            });

            return filteredSprints;
        } else {
            return allSprints;
        }
    }

    return null;
};
