import { Store } from 'use-global-hook';
import { State } from '../store/State';

export const SetDashboardReady = (store: Store<State, unknown>, dashboardReady: boolean): void => {
    const ui = { ...store.state.ui };
    ui.dashboard.ready = dashboardReady;
    store.setState({
        ...store.state,
        ui,
    });
};
