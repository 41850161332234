import { SprintVelocities } from '../SprintVelocities';
import { ChartData } from '../../../interfaces/ChartData';
import { Nullable } from '@aviture/interfaces';
import { State } from '../../../store/State';

export const SprintVelocitiesChartData = (state: State): Nullable<ChartData> => {
    const sprintVelocities = SprintVelocities(state);

    if (sprintVelocities) {
        const metric: ChartData = [['Sprint', 'Actual', 'Commited']];

        sprintVelocities.forEach((sprint) => {
            metric.push([sprint.name, sprint.actual, sprint.committed]);
        });

        return metric;
    }

    return null;
};
