import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useGlobal from '../../store';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import PageContainer from './PageContainer';
import Grid from '@mui/material/Grid';
import { CurrentDashboardFilter } from '../../selectors/ui/CurrentDashboardFilter';
import { DashboardReady } from '../../selectors/ui/DashboardReady';
import DashboardFilter from '../../components/DashboardFilter';
import AverageVelocity from '../../metrics/AverageVelocity';
import AverageHitRate from '../../metrics/AverageHitRate';
import SprintGoalHitRate from '../../metrics/SprintGoalHitRate';
import VelocityReport from '../../metrics/VelocityReport';
import TeamHitRate from '../../metrics/TeamHitRate';
import { AverageVelocityChartData } from '../../selectors/productivity/chart/AverageVelocityChartData';
import { AverageHitRateChartData } from '../../selectors/productivity/chart/AverageHitRateChartData';
import { SprintGoalHitRateChartData } from '../../selectors/productivity/chart/SprintGoalHitRateChartData';
import { SprintVelocitiesChartData } from '../../selectors/productivity/chart/SprintVelocitiesChartData';
import { TeamHitRateChartData } from '../../selectors/productivity/chart/TeamHitRateChartData';

const ProductivityPage: React.FC = () => {
    const theme = useTheme();
    const { teamId } = useParams();
    const [state, actions] = useGlobal();

    useEffect(() => {
        const loadData = async () => {
            if (teamId) {
                actions.SetCurrentTeam(teamId);
            } else {
                actions.SetFirstTeamAsCurrent();
            }
            actions.SetDashboardReady(true);
        };

        loadData();
    }, [actions, state.alwaysNull, teamId]);

    const currentFilter = CurrentDashboardFilter(state);
    const goalHitRateData = SprintGoalHitRateChartData(state);

    return (
        <PageContainer activePage="productivity">
            <Box>
                {DashboardReady(state) ? (
                    <React.Fragment>
                        <Box
                            sx={{
                                marginTop: theme.spacing(2),
                                marginBottom: theme.spacing(3),
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <DashboardFilter />
                        </Box>
                        <Grid
                            container
                            spacing={3}
                            sx={{
                                paddingBottom: theme.spacing(2),
                            }}
                        >
                            <Grid item xs={12} md={6} xl={4}>
                                <AverageVelocity data={AverageVelocityChartData(state)} filter={currentFilter} />
                            </Grid>
                            <Grid item xs={12} md={6} xl={4}>
                                <AverageHitRate data={AverageHitRateChartData(state)} filter={currentFilter} />
                            </Grid>
                            <Grid item xs={12} md={6} xl={4}>
                                <SprintGoalHitRate
                                    data={goalHitRateData.metric}
                                    achieved={goalHitRateData.achieved}
                                    total={goalHitRateData.total}
                                    filter={currentFilter}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} xl={4}>
                                <TeamHitRate data={TeamHitRateChartData(state)} filter={currentFilter} />
                            </Grid>
                            <Grid item xs={12} md={6} xl={4}>
                                <VelocityReport data={SprintVelocitiesChartData(state)} filter={currentFilter} />
                            </Grid>
                        </Grid>
                    </React.Fragment>
                ) : (
                    <div>Loading...</div>
                )}
            </Box>
        </PageContainer>
    );
};

export default ProductivityPage;
