import { Nullable, Sprint } from '@aviture/interfaces';

export const GetResolvedDefectsCount = (sprintData: Nullable<Sprint>): number => {
    if (sprintData === null) {
        return 0;
    }

    const closedDefectsThisSprint = sprintData.metrics.quality.closedDefectsThisSprint;
    if (closedDefectsThisSprint) {
        let count = 0;
        Object.keys(closedDefectsThisSprint).forEach((severity) => {
            count += closedDefectsThisSprint[severity as 'lowest' | 'low' | 'medium' | 'high' | 'urgent'];
        });

        return count;
    }

    return 0;
};
