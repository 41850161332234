import { State } from '../../../store/State';
import { Nullable } from '@aviture/interfaces';
import { FilteredSprints } from '../../sprint/FilteredSprints';
import { CalculateHitRate } from '../../../math/CalculateHitRate';

interface SprintGoalHitRate {
    metric: Nullable<number | string>;
    achieved: number;
    total: number;
}

export const SprintGoalHitRateChartData = (state: State): SprintGoalHitRate => {
    const sprints = FilteredSprints(state);

    if (sprints && sprints.length) {
        let achieved = 0;
        let missed = 0;

        sprints.forEach((sprint) => {
            if (sprint.goal.trackGoal) {
                if (sprint.goal.achieved) {
                    achieved += 1;
                } else {
                    missed += 1;
                }
            }
        });

        if (achieved === 0 && missed === 0) {
            return {
                metric: 'N/A',
                achieved: 0,
                total: 0,
            };
        } else {
            return {
                metric: CalculateHitRate(achieved + missed, achieved),
                achieved,
                total: achieved + missed,
            };
        }
    }

    return {
        metric: 'N/A',
        achieved: 0,
        total: 0,
    };
};
