import { CalculateSum } from './CalculateSum';

export const CalculateAverage = (numbers: number[], precision?: number): number => {
    if (numbers.length) {
        const average = CalculateSum(numbers) / numbers.length;
        if (precision) {
            return Number.parseFloat(average.toFixed(precision));
        }

        return average;
    }

    return 0;
};
