import { State } from '../../store/State';
import { Nullable, Sprint } from '@aviture/interfaces';
import { AllSprints } from './AllSprints';
import { CurrentSprintId } from './CurrentSprintId';

export const CurrentSprint = (state: State): Nullable<Sprint> => {
    const allSprints = AllSprints(state);
    const selectedSprintId = CurrentSprintId(state);

    if (selectedSprintId !== null && allSprints !== null) {
        const selectedSprint = allSprints.find((sprint) => {
            return sprint.id === selectedSprintId;
        });

        if (selectedSprint) {
            return selectedSprint;
        }
    }

    return null;
};
