import { AverageTimeInStatus } from '../AverageTimeInStatus';
import { ChartData } from '../../../interfaces/ChartData';
import { Nullable } from '@aviture/interfaces';
import { Statuses } from '../../team/Statuses';
import { State } from '../../../store/State';

export const AverageTimeInStatusChartData = (state: State): Nullable<ChartData> => {
    const avgTimeInStatuses = AverageTimeInStatus(state);
    const statuses = Statuses(state);
    if (avgTimeInStatuses && Object.keys(avgTimeInStatuses).length && statuses && statuses.length) {
        const metric: ChartData = [['Sprint', ...statuses]];

        avgTimeInStatuses.forEach((sprintAvgTimeInStatus) => {
            const sprintNumbers = statuses.map((status) => {
                if (sprintAvgTimeInStatus.metric && sprintAvgTimeInStatus.metric[status]) {
                    return sprintAvgTimeInStatus.metric[status];
                } else {
                    return 0;
                }
            });
            metric.push([sprintAvgTimeInStatus.name, ...sprintNumbers]);
        });

        return metric;
    }

    return null;
};
