import { State } from '../../store/State';
import { AppMetadata } from '../metadata/AppMetadata';
import { CurrentUser } from '../user/CurrentUser';
import { GetEnabledFeatureToggleIds } from './utils/GetEnabledFeatureToggleIds';
import { GetFeatureTogglesByCategory } from './utils/GetFeatureTogglesByCategory';

export const GetEnabledFeatureTogglesByCategory = (state: State, category: string): string[] => {
    const metadata = AppMetadata(state);
    const appSectionsFeatureToggles = GetFeatureTogglesByCategory(metadata, category);
    const currentUser = CurrentUser(state);
    return GetEnabledFeatureToggleIds(currentUser, appSectionsFeatureToggles);
};
