import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface IProps {
    primaryText: string;
    secondaryText?: string;
    icon: unknown;
}

const EmptyView: React.FC<IProps> = (props) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                '.MuiSvgIcon-root': {
                    height: '64px',
                    width: '64px',
                },
            }}
        >
            {props.icon}
            <Typography variant="h5">{props.primaryText}</Typography>
            {props.secondaryText ? <Typography variant="caption">{props.secondaryText}</Typography> : null}
        </Box>
    );
};

export default EmptyView;
