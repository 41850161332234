import { Store } from 'use-global-hook';
import { State } from '../store/State';
import { API } from 'aws-amplify';
import { ClearEmployees } from './ClearEmployees';
import { GetAllEmployees } from './GetAllEmployees';
import { SetAppLoading } from './SetAppLoading';
import { SpecificEmployee } from '../selectors/employee/SpecificEmployee';

export const DeleteEmployee = async (store: Store<State, unknown>, employeeId: string): Promise<void> => {
    if (employeeId !== null) {
        const employee = SpecificEmployee(store.state, employeeId);

        if (employee !== null) {
            employee.active = false;

            const init = {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                body: employee,
            };

            try {
                SetAppLoading(store, true);
                await API.put('JiraMetricsApi', '/employees/updateEmployee', init);
                ClearEmployees(store);
                await GetAllEmployees(store);
                SetAppLoading(store, false);
            } catch (err) {
                SetAppLoading(store, false);
                console.log(`An error occurred ${JSON.stringify(err)}`);
            }
        }       
    }
};
