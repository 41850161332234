import React, { useEffect, useState } from 'react';
import useGlobal from '../../store';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CurrentUserIsAdmin } from '../../selectors/user/CurrentUserIsAdmin';
import { CurrentTeamId } from '../../selectors/team/CurrentTeamId';
import EmptyView from '../../components/EmptyView';
import GroupsIcon from '@mui/icons-material/Groups';
import PageContainer from './PageContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Teams } from '../../selectors/team/Teams';
import TeamsOptionMenu from '../../components/TeamsOptionMenu';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const TeamsAdminPage: React.FC = () => {
    const theme = useTheme();
    const [state, actions] = useGlobal();
    const [ready, setReady] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        const loadData = async () => {
            setReady(false);
            await actions.GetAllTeams();
            const currentTeamId = CurrentTeamId(state);

            if (currentTeamId === null) {
                actions.SetFirstTeamAsCurrent();
            }
            setReady(true);
        };

        loadData();
    }, [actions, state.teams, state.alwaysNull]);

    const currentUserIsAdmin = CurrentUserIsAdmin(state);
    const teams = Teams(state);

    const handleAddTeam = () => {
        navigate('/admin/teams/create');
    };

    return (
        <PageContainer activePage="teams">
            {ready ? (
                <Box sx={{
                    marginBottom: theme.spacing(2),
                    paddingBottom: theme.spacing(2),
                }}>
                    {currentUserIsAdmin ? (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                width: '100%',
                                marginBottom: theme.spacing(2),
                            }}
                        >
                            <Button color="primary" variant="contained" onClick={handleAddTeam}>
                                Add Team
                            </Button>
                        </Box>
                    ) : null}
                    {teams !== null && teams.length ? (
                        <TableContainer component={Paper}>
                            <Table aria-label="all-employees-table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Program</TableCell>
                                        <TableCell>Active</TableCell>
                                        <TableCell width={64}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {teams.map((team, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Typography>{team.name}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{team.program}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{team.active === true ? 'True' : 'False'}</Typography>
                                            </TableCell>
                                            <TableCell
                                                width={64}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-end',
                                                }}
                                            >
                                                <TeamsOptionMenu team={team} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <EmptyView
                            primaryText="No teams found"
                            secondaryText="Click on Add Team to create the first one."
                            icon={<GroupsIcon />}
                        />
                    )}
                </Box>
            ) : (
                <Box>Loading...</Box>
            )}
        </PageContainer>
    );
};

export default TeamsAdminPage;
