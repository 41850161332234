import { Store } from 'use-global-hook';
import { State } from '../store/State';

export const SetAppLoading = (store: Store<State, unknown>, appLoading: boolean): void => {
    const ui = { ...store.state.ui };
    ui.appLoading = appLoading;
    store.setState({
        ...store.state,
        ui,
    });
};
