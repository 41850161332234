import { State } from '../../store/State';
import { AllEmployees } from './AllEmployees';
import { Employee } from '@aviture/interfaces';
import { CurrentTeam } from '../team/CurrentTeam';

export const EmployeesNotOnCurrentTeam = (state: State): Employee[] => {
    const allEmployees = AllEmployees(state);
    const currentTeam = CurrentTeam(state);

    if (allEmployees !== null && currentTeam !== null) {
        return allEmployees.filter((employee) => {
            const alreadyOnTeam = !!currentTeam.people?.find((teamMember) => {
                return employee.id === teamMember.employeeId;
            });

            return !alreadyOnTeam;
        });
    }

    return [];
};
