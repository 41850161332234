export const DateComparator = (a: Date, b: Date): number => {
    const dateAInMillis = a.valueOf();
    const dateBInMillis = b.valueOf();

    if (dateAInMillis < dateBInMillis) {
        return -1;
    } else if (dateAInMillis > dateBInMillis) {
        return 1;
    } else {
        return 0;
    }
};
