import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

interface Props {
    goal?: string;
    goalAchieved?: boolean;
    rationale?: string;
    sprintId: string;
}

const SprintGoalAchievement: React.FC<Props> = (props) => {
    const theme = useTheme();

    return (
        <React.Fragment>
            {props.goal ? (
                <React.Fragment>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: theme.spacing(1),
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: theme.typography.fontWeightBold,
                                flexShrink: 0,
                            }}
                            component="span"
                        >
                            Did the team meet the Sprint Goal?
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                marginLeft: theme.spacing(1),
                            }}
                        >
                            {props.goalAchieved === undefined ? (
                                <React.Fragment>
                                    <Typography>Unknown</Typography>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {props.goalAchieved === true ? (
                                        <Typography>Yes</Typography>
                                    ) : (
                                        <Typography>No</Typography>
                                    )}
                                </React.Fragment>
                            )}
                        </Box>
                    </Box>
                    {props.rationale ? (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: theme.typography.fontWeightBold,
                                    flexShrink: 0,
                                }}
                                component="span"
                            >
                                Rationale
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    marginLeft: theme.spacing(1),
                                }}
                            >
                                <Typography>{props.rationale}</Typography>
                            </Box>
                        </Box>
                    ) : undefined}
                </React.Fragment>
            ) : undefined}
        </React.Fragment>
    );
};

export default SprintGoalAchievement;
