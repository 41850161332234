import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';

const RectangularChartLoader: React.FC = () => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Skeleton
                variant="text"
                width={350}
                sx={{
                    marginBottom: theme.spacing(2),
                }}
                animation="wave"
            />
            <Skeleton
                variant="text"
                width={175}
                sx={{
                    marginBottom: theme.spacing(2),
                }}
                animation="wave"
            />
            <Skeleton variant="rectangular" width={500} height={256} animation="wave" />
        </Box>
    );
};

export default RectangularChartLoader;
