import { Store } from 'use-global-hook';
import { State } from '../store/State';
import { Team } from '@aviture/interfaces';
import { API } from 'aws-amplify';
import { SetAppLoading } from './SetAppLoading';

export const CreateTeam = async (store: Store<State, unknown>, team: Team): Promise<void> => {
    const init = {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        body: {...team, sprints: [], people: [], active: true},
    };
    const forms = store.state.forms;

    try {
        SetAppLoading(store, true);

        forms.createTeam.submitting = true;
        store.setState({
            ...store.state,
            forms,
        });
        const responseBody = await API.put('JiraMetricsApi', '/teams/updateTeam', init);

        forms.createTeam = {
            error: false,
            response: responseBody as Team,
            submitted: true,
            submitting: false,
        };
        store.setState({
            ...store.state,
            forms,
        });

        SetAppLoading(store, false);
    } catch (err) {
        console.log(`An error occurred ${JSON.stringify(err)}`);
        forms.createTeam = {
            error: true,
            response: null,
            submitted: true,
            submitting: false,
        };
        store.setState({
            ...store.state,
            forms,
        });

        SetAppLoading(store, false);
    }
};
