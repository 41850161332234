import { ChartData } from '../../../interfaces/ChartData';
import { Nullable } from '@aviture/interfaces';
import { State } from '../../../store/State';
import { CreatedDefectsBySprint } from '../CreatedDefectsBySprint';

export const DefectsBySprintChartData = (state: State): Nullable<ChartData> => {
    const escapedDefects = CreatedDefectsBySprint(state);
    if (escapedDefects && escapedDefects.length) {
        const chartData: ChartData = [['Sprint', 'Defects']];

        escapedDefects.forEach((sdc) => {
            chartData.push([sdc.name, sdc.count]);
        });

        return chartData;
    }

    return null;
};
