import {
    Employee,
    Metadata,
    Nullable,
    Team,
    User,
} from '@aviture/interfaces';
import format from 'date-fns/format';
import subDays from 'date-fns/subDays';

interface UserInterface {
    ready: boolean;
    appLoading: boolean;
    dashboard: Dashboard;
    instruction: Nullable<string | Element>;
    activeSection: Nullable<string>;
    organization: OrganizationFilter;
    showInactiveTeams: boolean;
}

export interface FilterInfo {
    label: string;
    period: Nullable<string>;
    startDate: Nullable<string>;
    endDate: Nullable<string>;
}

interface Dashboard {
    ready: boolean;
    filter: FilterInfo;
}

export interface OrganizationFilter {
    month: Nullable<number>;
    year: Nullable<number>;
}

export type AlertSeverity = 'success' | 'info' | 'warning' | 'error';

export interface FormData<T> {
    error: boolean;
    response: Nullable<T>;
    submitted: boolean;
    submitting: boolean;
}

interface Forms {
    createEmployee: FormData<Employee>;
    createTeam: FormData<Team>;
}

export interface State {
    alert: {
        severity?: AlertSeverity;
        message: Nullable<string>;
    };
    alwaysNull: null;
    currentSprintId: Nullable<string>;
    currentTeamId: Nullable<string>;
    employees: Nullable<Employee[]>;
    forms: Forms;
    metadata: Nullable<Metadata[]>;
    teams: Nullable<Team[]>;
    ui: UserInterface;
    user: Nullable<User>;
}

export const generateInitialState = (): State => {
    return {
        alert: {
            message: null,
        },
        /** Silly but allows useGlobal to reference state when it doesn't need to watch */
        alwaysNull: null,
        currentSprintId: null,
        currentTeamId: null,
        employees: null,
        forms: generateInitialForms(),
        metadata: null,
        teams: null,
        ui: {
            appLoading: true,
            ready: false,
            dashboard: {
                ready: false,
                filter: {
                    label: 'Last 90 Days',
                    period: '90',
                    startDate: format(subDays(new Date(), 90), 'yyyy/MM/dd'),
                    endDate: format(new Date(), 'yyyy/MM/dd'),
                },
            },
            instruction: null,
            activeSection: null,
            organization: {
                month: new Date().getMonth() + 1,
                year: new Date().getFullYear(),
            },
            showInactiveTeams: false,
        },
        user: null,
    };
}

export const generateInitialForms = (): Forms => {
    return {
        createEmployee: {
            error: false,
            response: null,
            submitted: false,
            submitting: false,
        },
        createTeam: {
            error: false,
            response: null,
            submitted: false,
            submitting: false,
        },
    };
};
