import { State } from '../../../store/State';
import { Nullable } from '@aviture/interfaces';
import { FilteredSprints } from '../../sprint/FilteredSprints';
import { CalculateAverage } from '../../../math/CalculateAverage';

export const AverageVelocityChartData = (state: State): Nullable<number> => {
    const sprints = FilteredSprints(state);

    if (sprints && sprints.length) {
        let metric = 0;
        const velocities = sprints.map((sprint) => {
            const actual = sprint.metrics?.productivity.velocity?.actual;
            return actual ? actual : 0;
        });

        metric = CalculateAverage(velocities, 2);

        return metric;
    }

    return null;
};
