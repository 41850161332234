import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import SubNavigation from '../../components/SubNavigation';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import { useTheme } from '@mui/material/styles';
import useGlobal from '../../store';

interface Props {
    activePage: string;
    children: unknown;
}

const OrganizationPageContainer: React.FC<Props> = (props) => {
    const theme = useTheme();
    const [state, actions] = useGlobal();

    useEffect(() => {
        const loadData = async () => {
            await actions.SetActiveSection('organization');
        };

        loadData();
    }, [actions, state.alwaysNull]);

    const pages = [
        {
            to: `/organization/kpi`,
            id: 'kpi',
            icon: <ScatterPlotIcon />,
            text: 'KPIs',
        },
    ];

    return (
        <Box
            sx={{
                display: 'flex',
                height: '100%',
            }}
        >
            <SubNavigation activePage={props.activePage} pages={pages} />
            <Box
                sx={{
                    height: '100%',
                    width: '100%',
                    overflowY: 'auto',
                    margin: theme.spacing(2),
                }}
            >
                {props.children}
            </Box>
        </Box>
    );
};

export default OrganizationPageContainer;
