import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import DeleteEmployeeDialog from './DeleteEmployeeDialog';
import { Employee } from '@aviture/interfaces';

interface Props {
    onClick: () => void;
    employee: Employee;
}

const DeleteEmployeeMenuItem: React.FC<Props> = (props) => {
    const [showDialog, setShowDialog] = useState<boolean>(false);

    const showDeleteEmployeeDialog = () => {
        props.onClick();
        setShowDialog(true);
    };

    const hideDeleteEmployeeDialog = () => {
        setShowDialog(false);
    };

    return (
        <React.Fragment>
            <React.Fragment>
                <MenuItem onClick={showDeleteEmployeeDialog}>Delete</MenuItem>
                <DeleteEmployeeDialog
                    employeeId={props.employee.id as string}
                    employeeName={`${props.employee.firstName} ${props.employee.lastName}`}
                    onClose={hideDeleteEmployeeDialog}
                    open={showDialog}
                />
            </React.Fragment>
        </React.Fragment>
    );
};

export default DeleteEmployeeMenuItem;
