import { ChartData } from '../../../interfaces/ChartData';
import { Nullable } from '@aviture/interfaces';
import { State } from '../../../store/State';
import { OpenDefectsBySeverity } from '../OpenDefectsBySeverity';

export const OpenDefectsBySeverityChartData = (state: State): Nullable<ChartData> => {
    const openDefectsBySeverity = OpenDefectsBySeverity(state);

    if (openDefectsBySeverity) {
        let totalItems = 0;

        const severityNames = ['Lowest', 'Low', 'Medium', 'High', 'Urgent'];
        const metric: ChartData = [['Data', ...severityNames]];
        const severityMetric = new Array<string | number>('Open Defects');
        severityNames.forEach((severity) => {
            const countForSeverity = openDefectsBySeverity[severity.toLowerCase()]
                ? openDefectsBySeverity[severity.toLowerCase()]
                : 0;
            totalItems += countForSeverity;
            severityMetric.push(countForSeverity);
        });

        metric.push(severityMetric);

        if (totalItems > 0) {
            return metric;
        } else {
            return null;
        }
    }

    return null;
};
