import React from 'react';
import useGlobalHook from 'use-global-hook';
import * as actions from '../actions';
import {
    generateInitialState,
    State,
} from './State';

const initialState: State = generateInitialState();

// eslint-disable-next-line react-hooks/rules-of-hooks, @typescript-eslint/no-explicit-any
const useGlobal = useGlobalHook<State, any>(React, initialState, actions);

export default useGlobal;
