export default {
    apiGatewayInvokeUrl: 'https://api.metrics.aviture.dev/v2',
    auth: {
        region: 'us-east-1',
        userPoolId: 'us-east-1_ju8y7GUJL',
        userPoolWebClientId: '4dc3bmj2fj8pelnostnon6m4sp',
        mandatorySignIn: true,
        authenticationFlowType: 'USER_PASSWORD_AUTH',
        identityPoolId: 'us-east-1:d85319b1-4911-49d6-a610-8b9aa8b684ab',
    },
    api: {
        endpoints: [
            {
                name: 'JiraMetricsApi',
                endpoint: 'https://api.metrics.aviture.dev/v2',
            },
        ],
    },
};
