import { Nullable, Team } from '@aviture/interfaces';
import { State } from '../../store/State';
import { Teams } from './Teams';

export const SpecificTeam = (state: State, teamId: string): Nullable<Team> => {
    const teams = Teams(state);

    if (teams && teams.length) {
        const team = teams.find((team) => {
            return team.id === teamId;
        });

        if (team) {
            return team;
        }
    }

    return null;
};
