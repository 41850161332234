import { ChartData } from '../../../interfaces/ChartData';
import { Nullable } from '@aviture/interfaces';
import { State } from '../../../store/State';
import { CreatedDefectsBySprint } from '../CreatedDefectsBySprint';
import { ResolvedDefectsBySprint } from '../ResolvedDefectsBySprint';

export const CreatedVsResolvedDefectsBySprintChartData = (state: State): Nullable<ChartData> => {
    const createdDefectsBySprint = CreatedDefectsBySprint(state);
    const resolvedDefectsBySprint = ResolvedDefectsBySprint(state);

    if (createdDefectsBySprint && createdDefectsBySprint.length && resolvedDefectsBySprint) {
        const metric: ChartData = [['Sprint', 'Created', 'Resolved']];

        createdDefectsBySprint.forEach((cdbs, index) => {
            metric.push([cdbs.name, cdbs.count, resolvedDefectsBySprint[index].count]);
        });

        return metric;
    }

    return null;
};
