import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

const ForgotPasswordHeader = () => {
    const theme = useTheme();

    return (
        <Box>
            <Typography variant="h5">Reset Your Password</Typography>
            <Typography variant="subtitle2" sx={{
                marginTop: '16px'
            }}>
                Enter your username below and we&apos;ll send you an email with instructions on how to reset your password.
            </Typography>
        </Box>
    );
};

export default ForgotPasswordHeader;
