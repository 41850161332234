import { Nullable, Sprint } from '@aviture/interfaces';
import { CalculateHitRate } from '../../../math/CalculateHitRate';

export const GetHitRate = (sprintData: Nullable<Sprint>): number | undefined => {
    if (sprintData === null) {
        return;
    }

    const velocity = sprintData.metrics.productivity.velocity;
    if (velocity && velocity.committed && velocity.actual) {
        const hitRate = CalculateHitRate(velocity.committed, velocity.actual);
        return hitRate;
    }

    return;
};
