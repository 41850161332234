import { State } from '../../store/State';
import { Nullable, CountMap } from '@aviture/interfaces';
import { FilteredSprints } from '../sprint/FilteredSprints';

export const OpenDefectsBySeverity = (state: State): Nullable<CountMap> => {
    const sprints = FilteredSprints(state);

    if (sprints && sprints.length) {
        const sprintToEvaluate = sprints[sprints.length - 1];
        return sprintToEvaluate.metrics.quality.openDefects;
    }

    return null;
};
