import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { AppReady } from '../selectors/ui/AppReady';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useGlobal from '../store';

interface Props {
    open: boolean;
    onClose: () => void;
    employeeId: string;
    employeeName: string;
}

const DeleteEmployeeDialog: React.FC<Props> = (props) => {
    const [state, actions] = useGlobal();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const hideDialog = () => {
        props.onClose();
    };

    const removeEmployee = async () => {
        props.onClose();
        await actions.DeleteEmployee(props.employeeId);
    };

    return (
        <React.Fragment>
            {AppReady(state) ? (
                <Dialog
                    fullScreen={fullScreen}
                    open={props.open}
                    onClose={hideDialog}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onBackdropClick={() => {}}
                    disableEscapeKeyDown
                >
                    <DialogTitle id="delete-employee-title">Delete Employee?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="delete-employee-text">
                            Are you sure you want to delete {props.employeeName}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={hideDialog} color="secondary" variant="contained">
                            No, Cancel
                        </Button>
                        <Button onClick={removeEmployee} color="primary" variant="contained">
                            Yes, Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            ) : undefined}
        </React.Fragment>
    );
};

export default DeleteEmployeeDialog;
