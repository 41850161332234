import React from 'react';
import Box from '@mui/material/Box';
import { CalculateHitRate } from '../math/CalculateHitRate';
import { GetHitRateForSprints } from '../selectors/sprint/utils/GetHitRateForSprints';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Sprint, Team } from '@aviture/interfaces';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { SxProps, Theme, useTheme } from '@mui/material/styles';

interface Props {
    teams: Team[];
    sx?: SxProps<Theme>;
}

const ProductivityKpiCard: React.FC<Props> = (props) => {
    const theme = useTheme();

    const organizationCellStyle: SxProps<Theme> = {
        fontWeight: 'bold',
        fontStyle: 'italic',
    };

    const generateGoalsCell = (sprints: Sprint[], sx?: SxProps<Theme>) => {
        let achieved = 0;
        let missed = 0;

        sprints.forEach((sprint) => {
            if (sprint.goal.trackGoal) {
                if (sprint.goal.achieved) {
                    achieved += 1;
                } else {
                    missed += 1;
                }
            }
        });

        return (
            <TableCell align="center" sx={sx}>
                {achieved} / {achieved + missed}&nbsp;
                {achieved + missed > 0 ? `(${CalculateHitRate(achieved + missed, achieved)}%)` : ''}
            </TableCell>
        );
    };

    let allSprints: Array<Sprint> = [];
    const tableRows = props.teams.map((team, index) => {
        const sprints: Sprint[] = team.sprints === undefined ? [] : team.sprints;
        allSprints = allSprints.concat(sprints);

        return (
            <TableRow
                key={team.id}
                sx={{
                    backgroundColor: index % 2 === 0 ? theme.palette.common.white : theme.palette.grey[100],
                }}
            >
                <TableCell>
                    {team.name} ({team.program})
                </TableCell>
                <TableCell align="center">{sprints.length}</TableCell>
                <TableCell align="center">{GetHitRateForSprints(sprints)}%</TableCell>
                {generateGoalsCell(sprints)}
            </TableRow>
        );
    });

    const organizationRow = (
        <TableRow
            sx={{
                backgroundColor: allSprints.length % 2 === 0 ? theme.palette.common.white : theme.palette.grey[100],
            }}
        >
            <TableCell
                sx={organizationCellStyle}
            >
                Organization
            </TableCell>
            <TableCell
                sx={organizationCellStyle}
                align="center"
            >
                {allSprints.length}
            </TableCell>
            <TableCell
                sx={organizationCellStyle}
                align="center"
            >
                {GetHitRateForSprints(allSprints)}%
            </TableCell>
            {generateGoalsCell(allSprints, {
                fontWeight: 'bold',
                fontStyle: 'italic',
            })}
        </TableRow>
    );

    return (
        <Box sx={props.sx}>
            <Card>
                <CardContent>
                    <Typography variant="h5">Productivity</Typography>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Team</TableCell>
                                    <TableCell align="center"># Sprints</TableCell>
                                    <TableCell align="center">Avg. Hit Rate</TableCell>
                                    <TableCell align="center">Goals Achieved</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableRows}
                                {organizationRow}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </Box>
    );
};

export default ProductivityKpiCard;
