import { State } from '../../store/State';
import { Nullable, SprintCountsByImpact } from '@aviture/interfaces';
import { FilteredSprints } from '../sprint/FilteredSprints';

export const ResolvedDefectsByImpactBySprint = (state: State): Nullable<SprintCountsByImpact[]> => {
    const sprints = FilteredSprints(state);

    if (sprints && sprints.length) {
        const metric = sprints.map((sprint) => {
            return {
                name: sprint.name,
                counts: sprint.metrics.quality.closedDefectsThisSprint,
            };
        });

        return metric;
    }

    return null;
};
