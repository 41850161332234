import React from 'react';
import SingleValueMetric from './SingleValueMetric';
import ChartContainer from '../components/ChartContainer';
import SingleValueChartLoader from '../components/SingleValueChartLoader';
import { Nullable } from '@aviture/interfaces';
import { FilterInfo } from '../store/State';

interface Props {
    data: Nullable<number>;
    filter: FilterInfo;
}

const AverageVelocity: React.FC<Props> = (props) => {
    return (
        <ChartContainer
            header={`Average Velocity (${props.filter.label})`}
            loading={false}
            size="small"
            loader={<SingleValueChartLoader />}
        >
            <SingleValueMetric value={props.data} />
        </ChartContainer>
    );
};

export default AverageVelocity;
