import React, { useState } from 'react';
import { Nullable } from '@aviture/interfaces';
import Box from '@mui/material/Box';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/material/styles';

interface Props {
    active: Nullable<string>;
    enabledSections: string[];
    sx?: SxProps<Theme>;
}

const AppSectionNavigator: React.FC<Props> = (props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const sections = [
        {
            id: 'teams',
            to: '/teams',
            text: 'Teams',
        },
        {
            id: 'organization',
            to: '/organization',
            text: 'Organization',
        },
        {
            id: 'admin',
            to: '/admin',
            text: 'Admin',
        },
    ].filter((section) => {
        return props.enabledSections.indexOf(section.id) >= 0;
    });
    const activeSession = sections.find((section) => {
        return section.id === props.active;
    });

    return (
        <Box sx={props.sx}>
            <Box
                onClick={sections.length > 1 ? handleClick : undefined}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                }}
            >
                <Typography variant="h6">{activeSession ? activeSession.text : ''}</Typography>
                {sections.length > 1 ? <KeyboardArrowDownIcon /> : null}
            </Box>
            <Menu
                id="app-section-navigator"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {sections.length > 0
                    ? sections.map((section) => {
                          return (
                              <MenuItem onClick={handleClose} key={section.id}>
                                  <Link to={section.to}>{section.text}</Link>
                              </MenuItem>
                          );
                      })
                    : null}
            </Menu>
        </Box>
    );
};

export default AppSectionNavigator;
