import { Store } from 'use-global-hook';
import { State } from '../store/State';
import { GetAllTeams } from './GetAllTeams';
import { SetAppLoading } from './SetAppLoading';
import { ShowInactiveTeams } from '../selectors/ui/ShowInactiveTeams';
import { SetFirstTeamAsCurrent } from './SetFirstTeamAsCurrent';

export const SetShowInactiveTeams = async (store: Store<State, unknown>, showInactiveTeams: boolean): Promise<void> => {
    const ui = { ...store.state.ui };
    ui.showInactiveTeams = showInactiveTeams;

    store.setState({
        ...store.state,
        teams: null,
        ui,
    });

    SetAppLoading(store, true);
    await GetAllTeams(store, ShowInactiveTeams(store.state));
    SetAppLoading(store, false);
};
