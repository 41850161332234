import React from 'react';
import Box from '@mui/material/Box';
import { Nullable } from '@aviture/interfaces';
import Typography from '@mui/material/Typography';
import { useTheme, Theme } from '@mui/material/styles';

interface IProps {
    value: Nullable<number | string>;
    subtitle?: string;
    symbol?: string;
    options?: {
        warn?: {
            color: string;
            threshold: number;
        };
        error?: {
            color: string;
            threshold: number;
        };
        thresholdType?: 'lower' | 'upper';
    };
}

const determineBackgroundColor = (theme: Theme, props: IProps): string => {
    const thresholdType = props.options?.thresholdType ? props.options.thresholdType : 'upper';
    const defaultBackgroundColor = theme.palette.common.white;

    if (!props.value) {
        return defaultBackgroundColor;
    }

    if (props?.options?.error) {
        if (thresholdType === 'lower') {
            if (props.value < props.options.error.threshold) {
                return props.options.error.color;
            }
        } else {
            if (props.value > props.options.error.threshold) {
                return props.options.error.color;
            }
        }
    }

    if (props?.options?.warn) {
        if (thresholdType === 'lower') {
            if (props.value < props.options.warn.threshold) {
                return props.options.warn.color;
            }
        } else {
            if (props.value > props.options.warn.threshold) {
                return props.options.warn.color;
            }
        }
    }

    return defaultBackgroundColor;
};

const SingleValueMetric: React.FC<IProps> = (props) => {
    const theme = useTheme();

    return props.value !== null ? (
        <Box
            sx={{
                textAlign: 'center',
                backgroundColor: determineBackgroundColor(theme, props),
                height: '100%',
                width: '100%',
            }}
        >
            <Typography variant="h1">
                {props.value}
                {props.symbol ? props.symbol : ''}
            </Typography>
            {props.subtitle ? <Typography variant="h5">{props.subtitle}</Typography> : ''}
        </Box>
    ) : (
        <Box>Loading...</Box>
    );
};

export default SingleValueMetric;
