import { State } from '../../store/State';
import { AllEmployees } from './AllEmployees';
import { Employee, Nullable } from '@aviture/interfaces';

export const SpecificEmployee = (state: State, employeeId: string): Nullable<Employee> => {
    const allEmployees = AllEmployees(state);

    if (allEmployees !== null) {
        const specificEmployee = allEmployees.find((employee) => {
            return employee.id === employeeId;
        });

        return specificEmployee ? specificEmployee : null;
    }

    return null;
};
