import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { CurrentDashboardFilter } from '../selectors/ui/CurrentDashboardFilter';
import CustomFilterDialog from './CustomFilterDialog';
import { FilterInfo } from '../store/State';
import { useTheme } from '@mui/material/styles';
import useGlobal from '../store';
import format from 'date-fns/format';
import subDays from 'date-fns/subDays';

const DashboardFilter: React.FC = () => {
    const [state, actions] = useGlobal();
    const theme = useTheme();
    const [filter, setFilter] = useState(CurrentDashboardFilter(state));
    const [showCustomFilter, setShowCustomFilter] = useState(false);

    const updateFilter = (event: any) => {
        if (event.target.value === 'Custom') {
            setShowCustomFilter(true);
        } else if (event.target.value === 'Forever') {
            const endDate = new Date();
            const startDate = new Date(0);

            const filter: FilterInfo = {
                period: event.target.value,
                label: 'Forever',
                startDate: format(startDate, 'yyyy/MM/dd'),
                endDate: format(endDate, 'yyyy/MM/dd'),
            };
            setFilter(filter);
            actions.SetDashboardFilter(filter);
        } else {
            const endDate = new Date();
            const startDate = subDays(endDate, Number.parseInt(event.target.value));

            const filter: FilterInfo = {
                label: `Last ${event.target.value} Days`,
                period: event.target.value,
                startDate: format(startDate, 'yyyy/MM/dd'),
                endDate: format(endDate, 'yyyy/MM/dd'),
            };
            setFilter(filter);
            actions.SetDashboardFilter(filter);
        }
    };

    const hideCustomFilterDialog = () => {
        setShowCustomFilter(false);
    };

    const clearCustomFilter = () => {
        const endDate = new Date();
        const startDate = subDays(endDate, 90);

        const filter: FilterInfo = {
            label: 'Last 90 Days',
            period: '90',
            startDate: format(startDate, 'yyyy/MM/dd'),
            endDate: format(endDate, 'yyyy/MM/dd'),
        };
        setFilter(filter);
        actions.SetDashboardFilter(filter);
    };

    const setCustomFilter = (startDate: string, endDate: string) => {
        const filter: FilterInfo = {
            period: 'Custom',
            label: 'Custom',
            startDate,
            endDate,
        };
        setFilter(filter);
        actions.SetDashboardFilter(filter);
        setShowCustomFilter(false);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <FormControl variant="outlined">
                <InputLabel id="dashboard-filter-label">Filter</InputLabel>
                <Select id="dashboard-filter-menu" value={filter.period} onChange={updateFilter} label="Filter">
                    <MenuItem value="30">Last 30 Days</MenuItem>
                    <MenuItem value="90">Last 90 Days</MenuItem>
                    <MenuItem value="365">Last Year</MenuItem>
                    <MenuItem value="Forever">All Time</MenuItem>
                    <MenuItem value="Custom">Custom</MenuItem>
                </Select>
            </FormControl>
            {filter.period === 'Custom' ? (
                <Button sx={{
                    marginLeft: theme.spacing(2),
                }} variant="text" onClick={clearCustomFilter}>
                    Clear
                </Button>
            ) : null}
            <CustomFilterDialog
                open={showCustomFilter}
                startDate={CurrentDashboardFilter(state).startDate}
                endDate={CurrentDashboardFilter(state).endDate}
                onClose={hideCustomFilterDialog}
                onSubmit={setCustomFilter}
            />
        </Box>
    );
};

export default DashboardFilter;
