import { Store } from 'use-global-hook';
import { State } from '../store/State';

export const ClearInstruction = (store: Store<State, unknown>): void => {
    const ui = { ...store.state.ui };
    ui.instruction = null;
    store.setState({
        ...store.state,
        ui,
    });
};
