import { Store } from 'use-global-hook';
import { State } from '../store/State';
import { Team } from '@aviture/interfaces';
import { API } from 'aws-amplify';

export const GetAllTeams = async (store: Store<State, unknown>, includeInactive: boolean): Promise<void> => {
    const init = {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        queryStringParameters: {
            includeInactive: includeInactive,
        },
    };

    if (store.state.teams === null) {
        try {
            const responseBody = await API.get('JiraMetricsApi', '/teams/getAllTeams', init);
            let teams: Team[] = responseBody.teams as Team[];
            teams = teams.sort((teamA, teamB) => {
                return teamA.name.localeCompare(teamB.name);
            });
            store.setState({
                ...store.state,
                teams,
            });
        } catch (err) {
            console.log(`An error occurred ${JSON.stringify(err)}`);
        }
    }
};
