import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useGlobal from '../../store';
import Box from '@mui/material/Box';
import PageContainer from './PageContainer';

const GeneralAdminPage: React.FC = () => {
    const { teamId } = useParams();
    const [state, actions] = useGlobal();

    useEffect(() => {
        const loadData = async () => {
            if (teamId) {
                actions.SetCurrentTeam(teamId);
            } else {
                actions.SetFirstTeamAsCurrent();
            }
        };

        loadData();
    }, [actions, state.alwaysNull, teamId]);

    return (
        <PageContainer activePage="general">
            <Box>{`Hello I'm the General Admin Page`}</Box>
        </PageContainer>
    );
}

export default GeneralAdminPage;
