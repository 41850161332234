import { State } from '../../../store/State';
import { CurrentTeam } from '../../team/CurrentTeam';
import { GetTimeInStatus } from '../utils/GetTimeInStatus';
import { PreviousSprint } from '../PreviousSprint';
import { TimeInStatus } from '../../../interfaces/TimeInStatus';

export const PreviousTimeInStatus = (state: State): TimeInStatus[] => {
    const sprintData = PreviousSprint(state);
    const currentTeam = CurrentTeam(state);
    return GetTimeInStatus(sprintData, currentTeam);
};
