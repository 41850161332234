import React from 'react';
import BarSparkChart from '../metrics/BarSparkChart';
import Box from '@mui/material/Box';
import { ChartData } from '../interfaces/ChartData';
import { Nullable } from '@aviture/interfaces';
import Typography from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/material/styles';

interface Props {
    key?: string;
    primaryLabel: string;
    secondaryLabel?: string;
    chartData: Nullable<ChartData>;
    maxValue?: number;
    chartHeight?: string;
    showXAxis?: boolean;
    sx?: SxProps<Theme>;
}

const BarSparkChartKpi: React.FC<Props> = (props) => {
    return (
        <Box sx={props.sx} key={props.key}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                minWidth: '300px',
            }}>
                <Typography variant="button">{props.primaryLabel}</Typography>
                {props.secondaryLabel ? <Typography variant="button">{props.secondaryLabel}</Typography> : null}
                <BarSparkChart
                    data={props.chartData}
                    maxValue={props.maxValue}
                    height={props.chartHeight}
                    showXAxis={props.showXAxis}
                />
            </Box>
        </Box>
    );
};

export default BarSparkChartKpi;
