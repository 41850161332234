import { FeatureToggle, Nullable, User } from '@aviture/interfaces';

export const GetEnabledFeatureToggleIds = (currentUser: Nullable<User>, featureToggles: FeatureToggle[]): string[] => {
    if (featureToggles && currentUser) {
        const featureToggleIds = new Array<string>();

        featureToggles.forEach((featureToggle) => {
            if (featureToggle.enabled) {
                if (!featureToggle.groups && !featureToggle.users) {
                    featureToggleIds.push(featureToggle.id);
                } else {
                    if (featureToggle.groups) {
                        const allowedGroups = featureToggle.groups;
                        let userInAGroup = false;

                        allowedGroups.forEach((group) => {
                            if (currentUser.groups) {
                                if (currentUser.groups.indexOf(group) >= 0) {
                                    userInAGroup = true;
                                }
                            }
                        });

                        if (userInAGroup) {
                            featureToggleIds.push(featureToggle.id);
                        }
                    }

                    if (featureToggle.users) {
                        if (featureToggle.users.indexOf(currentUser.id) >= 0) {
                            featureToggleIds.push(featureToggle.id);
                        }
                    }
                }
            }
        });

        return featureToggleIds;
    }

    return [];
};