import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            light: '#2f4f60',
            main: '#002736',
            dark: '#000010',
            contrastText: '#fff',
        },
        secondary: {
            light: '#6fadb7',
            main: '#3f7e87',
            dark: '#03525a',
            contrastText: '#fff',
        },
    },
});

export default theme;
