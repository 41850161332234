import React from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Box from '@mui/material/Box';
import CodeIcon from '@mui/icons-material/Code';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

interface Props {
    current: number;
    previous?: number;
    invert?: boolean;
    invertColorOnly?: boolean;
}

type Trend = 'up' | 'down' | 'neutral';

const determineTrend = (current: number, previous: number, invert?: boolean): Trend => {
    let numerator = current;
    let denominator = previous;

    if (invert) {
        numerator = previous;
        denominator = current;
    }

    return numerator > denominator ? 'up' : numerator < denominator ? 'down' : 'neutral';
};

const SprintTrend: React.FC<Props> = (props) => {
    const theme = useTheme();
    const trend =
        props.previous || props.previous === 0 ? determineTrend(props.current, props.previous, props.invert) : null;

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {trend === 'up' ? (
                <React.Fragment>
                    <ArrowUpwardIcon
                        sx={
                            props.invertColorOnly
                                ? {
                                      color: theme.palette.error.main,
                                  }
                                : props.invert
                                ? {
                                      color: theme.palette.error.main,
                                  }
                                : {
                                      color: theme.palette.success.main,
                                  }
                        }
                    />
                    <Typography sx={{ marginLeft: theme.spacing(1) }}>
                        from the previous sprint {`(${props.previous})`}
                    </Typography>
                </React.Fragment>
            ) : trend === 'down' ? (
                <React.Fragment>
                    <ArrowDownwardIcon
                        sx={
                            props.invertColorOnly
                                ? {
                                      color: theme.palette.success.main,
                                  }
                                : props.invert
                                ? {
                                      color: theme.palette.success.main,
                                  }
                                : {
                                      color: theme.palette.error.main,
                                  }
                        }
                    />
                    <Typography sx={{ marginLeft: theme.spacing(1) }}>
                        from the previous sprint {`(${props.previous})`}
                    </Typography>
                </React.Fragment>
            ) : trend === 'neutral' ? (
                <React.Fragment>
                    <CodeIcon
                        sx={{
                            color: theme.palette.grey[500],
                        }}
                    />
                    <Typography sx={{ marginLeft: theme.spacing(1) }}>
                        to the previous sprint {`(${props.previous})`}
                    </Typography>
                </React.Fragment>
            ) : (
                <Typography sx={{ marginLeft: theme.spacing(1) }}>No trend data available</Typography>
            )}
        </Box>
    );
};

export default SprintTrend;
