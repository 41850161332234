import { Store } from 'use-global-hook';
import { State } from '../store/State';
import { Team } from '@aviture/interfaces';

export const UpdateTeamData = (store: Store<State, unknown>, team: Team): void => {
    if (store.state.teams) {
        let existingTeams =  store.state.teams.filter((existingTeam) => {
            return existingTeam.id !== team.id;
        });
        existingTeams.push(team);
        existingTeams = existingTeams.sort((teamA, teamB) => {
            return teamA.name.localeCompare(teamB.name);
        });

        store.setState({
            ...store.state,
            teams: existingTeams,
        });
    }
};
