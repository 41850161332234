export const SEVERITY_ORDER = ["lowest", "low", "medium", "high", "urgent"];

export const DefectSeverityComparator = (a: string, b: string): number => {
    const sortOrderA = SEVERITY_ORDER.indexOf(a.trim().toLowerCase());
    const sortOrderB = SEVERITY_ORDER.indexOf(b.trim().toLowerCase());

    if (sortOrderA < sortOrderB) {
        return -1;
    } else if (sortOrderA > sortOrderB) {
        return 1;
    } else {
        return 0;
    }
};
