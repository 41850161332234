import { CurrentTeam } from './CurrentTeam';
import { State } from '../../store/State';

export const Statuses = (state: State): string[] => {
    const currentTeam = CurrentTeam(state);

    if (currentTeam !== null) {
        return currentTeam.statuses;
    }

    return [];
};
