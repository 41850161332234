import { CurrentTeamId } from './CurrentTeamId';
import { Nullable, Team } from '@aviture/interfaces';
import { State } from '../../store/State';
import { Teams } from './Teams';

export const CurrentTeam = (state: State): Nullable<Team> => {
    const teams = Teams(state);
    const currentTeamId = CurrentTeamId(state);

    if (teams && teams.length && currentTeamId) {
        const currentTeam = teams.find((team) => {
            return team.id === currentTeamId;
        });

        if (currentTeam) {
            return currentTeam;
        }
    }

    return null;
};
