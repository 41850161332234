import { Store } from 'use-global-hook';
import { State } from '../store/State';
import { User } from '@aviture/interfaces';
import { CreateUser } from './CreateUser';
import { API } from 'aws-amplify';

interface CognitoUser {
    id?: string;
    username: string;
    attributes: {
        [key: string]: string;
    };
}

const generateNewUser = (cognitoUser: CognitoUser): User => {
    return {
        id: cognitoUser.attributes.sub,
        name: cognitoUser.attributes.name,
    };
};

export const GetCurrentUser = async (store: Store<State, unknown>, cognitoUser: CognitoUser): Promise<void> => {
    const init = {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        queryStringParameters: {
            id: cognitoUser.attributes.sub,
        },
    };

    try {
        const responseBody = await API.get('JiraMetricsApi', '/users/getUser', init);
        if (responseBody.user === null) {
            await CreateUser(store, generateNewUser(cognitoUser));
        } else {
            const user: User = responseBody.user as User;

            store.setState({
                ...store.state,
                user,
            });
        }
    } catch (err) {
        console.log(`An error occurred ${JSON.stringify(err)}`);
    }
};
