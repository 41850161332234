import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RemoveTeamMemberMenuItem from './RemoveTeamMemberMenuItem';
import { TeamMemberViewModel } from '../interfaces/TeamMemberViewModel';
import UpdateTeamMemberMenuItem from './UpdateTeamMemberMenuItem';

interface Props {
    teamMember: TeamMemberViewModel;
}

const TeamMemberOptionsMenu: React.FC<Props> = (props) => {
    const [menuEl, setMenuEl] = useState(null);

    const handleMenuClick = (event: any) => {
        setMenuEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuEl(null);
    };

    return (
        <React.Fragment>
            <IconButton aria-label="options" onClick={handleMenuClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                id={`employee-${props.teamMember.id}-menu`}
                anchorEl={menuEl}
                keepMounted
                open={Boolean(menuEl)}
                onClose={handleMenuClose}
            >
                <UpdateTeamMemberMenuItem onClick={handleMenuClose} teamMember={props.teamMember} />
                <RemoveTeamMemberMenuItem onClick={handleMenuClose} teamMember={props.teamMember} />
            </Menu>
        </React.Fragment>
    );
};

export default TeamMemberOptionsMenu;
