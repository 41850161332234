
import { Sprint } from '@aviture/interfaces';
import { SprintViewModel } from '../interfaces/SprintViewModel';
import { v4 as uuidv4 } from 'uuid';

export const generateSprint = (data: SprintViewModel): Sprint => {
    const sprint: Sprint = {
        id: data.id ? data.id : uuidv4(),
        name: data.name,
        startDate: data.startDate,
        endDate: data.endDate,
        goal: {
            description: data.goal,
            achieved: data.goalAchieved,
            rationale: data.rationale,
            trackGoal: data.trackGoal,
        },
        metrics: {
            productivity: {
                velocity: data.velocity
            },
            quality: {
                openDefects: data.openDefects,
                newDefectsThisSprint: data.newDefectsThisSprint,
                closedDefectsThisSprint: data.closedDefectsThisSprint
            },
            predictability: {
                timeInStatus: data.timeInStatus
            }
        },
        actionItems: [], // TODO ADD ME
        techDebtAccumulated: [] // TODO ADD ME
    };

    return sprint;
};
