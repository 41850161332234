import React from 'react';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';

interface PageInfo {
    to: string;
    text: string;
    id: string;
    icon: React.ReactNode;
}

interface Props {
    activePage: string;
    pages: PageInfo[];
}

const SubNavigation: React.FC<Props> = (props) => {
    const theme = useTheme();
    return (
        <List
            sx={{
                backgroundColor: theme.palette.primary.main,
                width: '207px',
                flexShrink: 0,
                a: {
                    textDecoration: 'none',
                },
            }}
            component="nav"
            aria-label="sub navigation"
        >
            {props.pages.map((page) => {
                return (
                    <Link to={page.to} key={page.id}>
                        <ListItem
                            sx={{
                                color: theme.palette.common.white,
                                '&.Mui-selected': {
                                    backgroundColor: theme.palette.secondary.main,

                                    '&:hover': {
                                        backgroundColor: theme.palette.secondary.main,
                                    },
                                },
                                '&:hover': {
                                    backgroundColor: theme.palette.secondary.main,
                                },
                            }}
                            button
                            selected={props.activePage === page.id}
                        >
                            <ListItemIcon
                                sx={{
                                    color: theme.palette.common.white,
                                }}
                            >
                                {page.icon}
                            </ListItemIcon>
                            <ListItemText primary={page.text} />
                        </ListItem>
                    </Link>
                );
            })}
        </List>
    );
};

export default SubNavigation;
