import { ChartData } from '../../../interfaces/ChartData';
import { Nullable } from '@aviture/interfaces';
import { State } from '../../../store/State';
import { CreatedDefectsByImpactBySprint } from '../CreatedDefectsByImpactBySprint';
import { ResolvedDefectsByImpactBySprint } from '../ResolvedDefectsByImpactBySprint';

interface Counts {
    [key: string]: number;
}

export const DefectBuildUpChartData = (state: State): Nullable<ChartData> => {
    const createdBySprint = CreatedDefectsByImpactBySprint(state);
    const resolvedBySprint = ResolvedDefectsByImpactBySprint(state);
    const severityNames = ['Lowest', 'Low', 'Medium', 'High', 'Urgent'];

    if (createdBySprint && createdBySprint.length && resolvedBySprint) {
        const metric: ChartData = [['Sprint', ...severityNames]];

        createdBySprint.forEach((sprintMapping) => {
            const resolvedSprintMapping = resolvedBySprint.find((resolvedMapping) => {
                return resolvedMapping.name === sprintMapping.name;
            });
            const counts: Counts = {};
            severityNames.forEach((severity) => {
                counts[severity] =
                    (sprintMapping.counts[severity.toLowerCase()] | 0) -
                    (resolvedSprintMapping ? resolvedSprintMapping.counts[severity.toLowerCase()] | 0 : 0);
            });

            const sprintMetric: Array<string | number> = [sprintMapping.name];
            severityNames.forEach((name) => {
                sprintMetric.push(counts[name]);
            });

            metric.push(sprintMetric);
        });
        return metric;
    }

    return null;
};
