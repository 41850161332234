import React from 'react';
import Box from '@mui/material/Box';
import { CalculateSum } from '../math/CalculateSum';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Sprint, Team } from '@aviture/interfaces';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { SprintEndDateComparator } from '../comparators/SprintEndDateComparator';
interface Props {
    teams: Team[];
    sx?: SxProps<Theme>;
}

const QualityKpiCard: React.FC<Props> = (props) => {
    const theme = useTheme();
    const allSprints: Array<Sprint> = [];

    const organizationCellStyle: SxProps<Theme> = {
        fontWeight: 'bold',
        fontStyle: 'italic',
    };

    const tableRows = props.teams.map((team, index) => {
        const sprints: Sprint[] = team.sprints === undefined ? [] : team.sprints;
        sprints.sort(SprintEndDateComparator);

        if (sprints.length > 0) {
            const mostRecentSprint = sprints[sprints.length - 1];
            allSprints.push(mostRecentSprint);

            return (
                <TableRow
                    key={team.id}
                    sx={{
                        backgroundColor: index % 2 === 0 ? theme.palette.common.white : theme.palette.grey[100],
                    }}
                >
                    <TableCell>
                        {team.name} ({team.program})
                    </TableCell>
                    <TableCell align="center">{mostRecentSprint.metrics.quality.openDefects.lowest}</TableCell>
                    <TableCell align="center">{mostRecentSprint.metrics.quality.openDefects.low}</TableCell>
                    <TableCell align="center">{mostRecentSprint.metrics.quality.openDefects.medium}</TableCell>
                    <TableCell align="center">{mostRecentSprint.metrics.quality.openDefects.high}</TableCell>
                    <TableCell align="center">{mostRecentSprint.metrics.quality.openDefects.urgent}</TableCell>
                </TableRow>
            );
        } else {
            return (
                <TableRow
                    sx={{
                        backgroundColor: index % 2 === 0 ? theme.palette.common.white : theme.palette.grey[100],
                    }}
                >
                    <TableCell>
                        {team.name} ({team.program})
                    </TableCell>
                    <TableCell align="center">{'-'}</TableCell>
                    <TableCell align="center">{'-'}</TableCell>
                    <TableCell align="center">{'-'}</TableCell>
                    <TableCell align="center">{'-'}</TableCell>
                    <TableCell align="center">{'-'}</TableCell>
                </TableRow>
            );
        }
    });

    const organizationRow = (
        <TableRow
            sx={{
                backgroundColor: props.teams.length % 2 === 0 ? theme.palette.common.white : theme.palette.grey[100],
            }}
        >
            <TableCell sx={organizationCellStyle}>Organization</TableCell>
            <TableCell sx={organizationCellStyle} align="center">
                {CalculateSum(allSprints.map((sprint) => sprint.metrics.quality.openDefects.lowest))}
            </TableCell>
            <TableCell sx={organizationCellStyle} align="center">
                {CalculateSum(allSprints.map((sprint) => sprint.metrics.quality.openDefects.low))}
            </TableCell>
            <TableCell sx={organizationCellStyle} align="center">
                {CalculateSum(allSprints.map((sprint) => sprint.metrics.quality.openDefects.medium))}
            </TableCell>
            <TableCell sx={organizationCellStyle} align="center">
                {CalculateSum(allSprints.map((sprint) => sprint.metrics.quality.openDefects.high))}
            </TableCell>
            <TableCell sx={organizationCellStyle} align="center">
                {CalculateSum(allSprints.map((sprint) => sprint.metrics.quality.openDefects.urgent))}
            </TableCell>
        </TableRow>
    );

    return (
        <Box sx={props.sx}>
            <Card>
                <CardContent>
                    <Typography variant="h5">Quality</Typography>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Team</TableCell>
                                    <TableCell
                                        align="center"
                                        sx={{
                                            backgroundColor: theme.palette.success.dark,
                                            color: theme.palette.common.white,
                                        }}
                                    >
                                        Lowest
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        sx={{
                                            backgroundColor: theme.palette.success.light,
                                            color: theme.palette.common.white,
                                        }}
                                    >
                                        Low
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        sx={{
                                            backgroundColor: theme.palette.warning.light,
                                            color: theme.palette.common.black,
                                        }}
                                    >
                                        Medium
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        sx={{
                                            backgroundColor: theme.palette.error.light,
                                            color: theme.palette.common.white,
                                        }}
                                    >
                                        High
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        sx={{
                                            backgroundColor: theme.palette.error.main,
                                            color: theme.palette.common.white,
                                        }}
                                    >
                                        Urgent
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableRows}
                                {organizationRow}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </Box>
    );
};

export default QualityKpiCard;
