import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

const AvitureLogo = () => {
    const theme = useTheme();

    return (
        <Box sx={{
            paddingBottom: theme.spacing(2)
        }}>
            <img src="/Aviture-boxed-logo.png" alt="logo" width="100%" />
        </Box>
    );
};

export default AvitureLogo;
