import React from 'react';
import Box from '@mui/material/Box';
import { Chart } from 'react-google-charts';
import { ChartData } from '../interfaces/ChartData';
import { Nullable } from '@aviture/interfaces';
import { useTheme } from '@mui/material/styles';
import ChartContainer from '../components/ChartContainer';
import RectangularChartLoader from '../components/RectangularChartLoader';
import { FilterInfo } from '../store/State';

interface IProps {
    data: Nullable<ChartData>;
    filter: FilterInfo;
}

const TeamHitRate: React.FC<IProps> = (props) => {
    const theme = useTheme();

    return (
        <ChartContainer
            flush
            header={`Team Hit Rate (${props.filter.label})`}
            loading={false}
            size="large"
            loader={<RectangularChartLoader />}
        >
            {props.data && props.data.length ? (
                <Box sx={{
                    textAlign: 'center',
                    width: '100%',
                }}>
                    <Chart
                        height={'400px'}
                        chartType="ComboChart"
                        loader={<Box>Loading...</Box>}
                        data={props.data}
                        options={{
                            colors: ['#4285f4', theme.palette.success.light],
                            legend: {
                                alignment: 'center',
                                position: 'top',
                            },
                            vAxis: {
                                title: 'Accuracy',
                                logScale: false,
                            },
                            hAxis: {
                                title: 'Sprint',
                            },
                        }}
                        rootProps={{
                            'data-testid': 'team-hit-rate',
                        }}
                    />
                </Box>
            ) : (
                <Box>No data</Box>
            )}
        </ChartContainer>
    );
};

export default TeamHitRate;
