import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import SubNavigation from '../../components/SubNavigation';
import GroupIcon from '@mui/icons-material/Group';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import SettingsIcon from '@mui/icons-material/Settings';
import CodeIcon from '@mui/icons-material/Code';
import StarIcon from '@mui/icons-material/Star';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import SyncIcon from '@mui/icons-material/Sync';
import { CurrentTeamId } from '../../selectors/team/CurrentTeamId';
import { EnabledTeamPagesForCurrentUser } from '../../selectors/feature-toggles/EnabledTeamPagesForCurrentUser';
import { useTheme } from '@mui/material/styles';
import useGlobal from '../../store';

interface Props {
    activePage: string;
    children: unknown;
}

const PageContainer: React.FC<Props> = (props) => {
    const theme = useTheme();
    const [state, actions] = useGlobal();

    useEffect(() => {
        const loadData = async () => {
            await actions.SetActiveSection('teams');
        };

        loadData();
    }, [actions, state.alwaysNull]);

    const enabledPages = EnabledTeamPagesForCurrentUser(state);
    const pages = [
        {
            to: `/teams/${CurrentTeamId(state)}/sprints`,
            id: 'sprints',
            icon: <DirectionsRunIcon />,
            text: 'Sprints',
        },
        {
            to: `/teams/${CurrentTeamId(state)}/people`,
            id: 'people',
            icon: <GroupIcon />,
            text: 'People',
        },
        {
            to: `/teams/${CurrentTeamId(state)}/quality`,
            id: 'quality',
            icon: <StarIcon />,
            text: 'Quality',
        },
        {
            to: `/teams/${CurrentTeamId(state)}/productivity`,
            id: 'productivity',
            icon: <DoubleArrowIcon />,
            text: 'Productivity',
        },
        {
            to: `/teams/${CurrentTeamId(state)}/predictability`,
            id: 'predictability',
            icon: <SyncIcon />,
            text: 'Predictability',
        },
        {
            to: `/teams/${CurrentTeamId(state)}/code`,
            id: 'code',
            icon: <CodeIcon />,
            text: 'Code',
        },
        {
            to: `/teams/${CurrentTeamId(state)}/kpi`,
            id: 'kpi',
            icon: <ScatterPlotIcon />,
            text: 'KPIs',
        },
        {
            to: `/teams/${CurrentTeamId(state)}/settings`,
            id: 'settings',
            icon: <SettingsIcon />,
            text: 'Settings',
        },
    ].filter((page) => {
        return enabledPages.indexOf(page.id) >= 0;
    });

    return (
        <Box
            sx={{
                display: 'flex',
                height: '100%',
            }}
        >
            <SubNavigation activePage={props.activePage} pages={pages} />
            <Box
                sx={{
                    height: '100%',
                    width: '100%',
                    overflowY: 'auto',
                    margin: theme.spacing(2),
                }}
            >
                {props.children}
            </Box>
        </Box>
    );
};

export default PageContainer;
