import { Store } from 'use-global-hook';
import { State } from '../store/State';

export const SetActiveSection = (store: Store<State, unknown>, id: string): void => {
    const ui = { ...store.state.ui };
    ui.activeSection = id;
    store.setState({
        ...store.state,
        ui,
    });
};
