import { Store } from 'use-global-hook';
import { State } from '../store/State';

export const ClearAlert = (store: Store<State, unknown>): void => {
    store.setState({
        ...store.state,
        alert: {
            message: null,
            severity: undefined,
        },
    });
};
