import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

const SignInHeader = () => {
    const theme = useTheme();

    return (
        <Box sx={{
            padding: theme.spacing(2)
        }}>
            <Typography variant="h5">Sign in to Metrics</Typography>
        </Box>
    );
};

export default SignInHeader;
