import { State } from '../../../store/State';
import { Nullable } from '@aviture/interfaces';
import { FilteredSprints } from '../../sprint/FilteredSprints';
import { CalculateAverage } from '../../../math/CalculateAverage';
import { CalculateHitRate } from '../../../math/CalculateHitRate';

export const AverageHitRateChartData = (state: State): Nullable<number> => {
    const sprints = FilteredSprints(state);

    if (sprints && sprints.length) {
        let metric = 0;
        const hitRates = new Array<number>();

        sprints.forEach((sprint) => {
            const committed = sprint.metrics?.productivity.velocity?.committed;
            const actual = sprint.metrics?.productivity.velocity?.actual;

            hitRates.push(CalculateHitRate(committed, actual));
        });

        if (hitRates.length) {
            metric = CalculateAverage(hitRates, 2);
        }

        return metric;
    }

    return null;
};
