import React, { useState, useRef } from 'react';
import useGlobal from '../../store';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CreateTeamForm } from '../../selectors/team/CreateTeamForm';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import PageContainer from './PageContainer';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

const CreateTeamPage: React.FC = () => {
    const theme = useTheme();
    const [state, actions] = useGlobal();
    const navigate = useNavigate();

    const [teamName, setTeamName] = useState<string>();
    const [teamNameValid, setTeamNameValid] = useState<boolean>();
    const [program, setProgram] = useState<string>();
    const [programValid, setProgramValid] = useState<boolean>();
    const [statuses, setStatuses] = useState<string>('In Progress, Peer Review, QA');
    const [statusesValid, setStatusesValid] = useState<boolean>(true);
    const [dirty, setDirty] = useState<boolean>(false);

    const teamNameInput = useRef<HTMLDivElement>(null);

    const handleTeamNameChange = (event: any) => {
        const value = event.target.value;
        setTeamName(value);
        setDirty(true);
        setTeamNameValid(value.trim().length > 0);
    };

    const handleProgramChange = (event: any) => {
        const value = event.target.value;
        setProgram(value);
        setDirty(true);
        setProgramValid(value.trim().length > 0);
    };

    const handleStatusesChange = (event: any) => {
        const value = event.target.value;
        setStatuses(value);
        setDirty(true);
        setProgramValid(value.trim().length > 0);
    };

    const clearForm = () => {
        setTeamName('');
        setTeamNameValid(false);
        setProgram('');
        setProgramValid(false);
        setStatuses('In Progress, Peer Review, QA');
        setStatusesValid(true);
        setDirty(false);
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (teamNameValid && programValid && statusesValid) {
            await actions.CreateTeam({
                name: teamName,
                program,
                statuses: statuses.split(',').map((status) => {
                    return status.trim();
                }),
            });

            if (CreateTeamForm(state).response) {
                actions.FireAlert('Team created successfully.', 'success');
                clearForm();
                actions.ClearTeams();
                navigate('/admin/teams');
            } else {
                actions.FireAlert('An error occurred attempting to create a team.', 'error');
            }
        }
    };

    const handleCancel = () => {
        clearForm();
        navigate('/admin/teams');
    };

    return (
        <PageContainer activePage="teams">
            <Typography variant="h6" sx={{ marginBottom: theme.spacing(3) }}>
                Create Team
            </Typography>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            id="teamName"
                            label="Team Name"
                            value={teamName}
                            variant="outlined"
                            required
                            error={dirty && !teamNameValid}
                            onChange={handleTeamNameChange}
                            autoFocus={true}
                            inputRef={teamNameInput}
                            inputProps={{ maxLength: 50 }}
                            sx={{
                                width: '350px',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="program"
                            label="Program Name"
                            value={program}
                            variant="outlined"
                            required
                            error={dirty && !programValid}
                            onChange={handleProgramChange}
                            inputProps={{ maxLength: 50 }}
                            sx={{
                                width: '350px',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="statuses"
                            label="Statuses"
                            value={statuses}
                            variant="outlined"
                            required
                            error={dirty && !statusesValid}
                            onChange={handleStatusesChange}
                            inputProps={{ maxLength: 100 }}
                            sx={{
                                width: '350px',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Button onClick={handleCancel} color="secondary" variant="contained">
                                Cancel
                            </Button>
                            <Button
                                onClick={handleSubmit}
                                color="primary"
                                variant="contained"
                                type="submit"
                                disabled={!teamNameValid || !programValid || !statusesValid}
                                sx={{
                                    marginLeft: theme.spacing(3),
                                }}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </PageContainer>
    );
};

export default CreateTeamPage;
