import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import RemoveTeamMemberDialog from './RemoveTeamMemberDialog';
import { TeamMemberViewModel } from '../interfaces/TeamMemberViewModel';

interface Props {
    onClick: () => void;
    teamMember: TeamMemberViewModel;
}

const RemoveTeamMemberMenuItem: React.FC<Props> = (props) => {
    const [showDialog, setShowDialog] = useState<boolean>(false);

    const showRemoveTeamMemberDialog = () => {
        props.onClick();
        setShowDialog(true);
    };

    const hideRemoveTeamMemberDialog = () => {
        setShowDialog(false);
    };

    return (
        <React.Fragment>
            <React.Fragment>
                <MenuItem onClick={showRemoveTeamMemberDialog}>Remove</MenuItem>
                <RemoveTeamMemberDialog
                    employeeId={props.teamMember.id}
                    employeeName={props.teamMember.name}
                    onClose={hideRemoveTeamMemberDialog}
                    open={showDialog}
                />
            </React.Fragment>
        </React.Fragment>
    );
};

export default RemoveTeamMemberMenuItem;
