import { Store } from 'use-global-hook';
import { State } from '../store/State';
import { Employee } from '@aviture/interfaces';
import { API } from 'aws-amplify';
import { AllEmployees } from '../selectors/employee/AllEmployees';
import { SetAppLoading } from './SetAppLoading';

export const GetAllEmployees = async (store: Store<State, unknown>): Promise<void> => {
    if (AllEmployees(store.state) === null) {
        const init = {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
        };

        try {
            SetAppLoading(store, true);
            const responseBody = await API.get('JiraMetricsApi', '/employees/getAllEmployees', init);
            const employees: Employee[] = responseBody.employees as Employee[];

            store.setState({
                ...store.state,
                employees,
            });
            SetAppLoading(store, false);
        } catch (err) {
            SetAppLoading(store, false);
            console.log(`An error occurred ${JSON.stringify(err)}`);
        }
    }
};
