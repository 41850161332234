import { Store } from 'use-global-hook';
import { State } from '../store/State';
import { API } from 'aws-amplify';
import { SpecificTeam } from '../selectors/team/SpecificTeam';
import { GetAllTeams } from './GetAllTeams';
import { SetAppLoading } from './SetAppLoading';
import { ShowInactiveTeams } from '../selectors/ui/ShowInactiveTeams';
import { ClearTeams } from './ClearTeams';

export const DeactivateTeam = async (store: Store<State, unknown>, teamId: string): Promise<void> => {
    const specificTeam = SpecificTeam(store.state, teamId);

    if (specificTeam !== null) {
        specificTeam.active = false;

        const init = {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            body: specificTeam,
        };

        SetAppLoading(store, true);
        await API.put('JiraMetricsApi', '/teams/updateTeam', init);
        await ClearTeams(store);
        await GetAllTeams(store, ShowInactiveTeams(store.state));
        SetAppLoading(store, false);
    }
};
