import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useGlobal from '../../store';
import { SpecificEmployee } from '../../selectors/employee/SpecificEmployee';
import { CurrentTeam } from '../../selectors/team/CurrentTeam';
import { CurrentUserIsAdmin } from '../../selectors/user/CurrentUserIsAdmin';
import TeamMemberDialog from '../../components/TeamMemberDialog';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import EmptyView from '../../components/EmptyView';
import { useTheme } from '@mui/material/styles';
import PageContainer from './PageContainer';
import Paper from '@mui/material/Paper';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TeamMemberOptionsMenu from '../../components/TeamMemberOptionsMenu';
import { TeamMemberViewModel } from '../../interfaces/TeamMemberViewModel';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

interface Person {
    id: string;
    name: string;
    role: string;
    allocation: string;
}

const PeoplePage: React.FC = () => {
    const theme = useTheme();
    const { teamId } = useParams();
    const [state, actions] = useGlobal();
    const [ready, setReady] = useState<boolean>(false);
    const [showAddTeamMemberDialog, setShowAddTeamMemberDialog] = useState<boolean>(false);
    const hideExcessInfo = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const loadData = async () => {
            if (teamId) {
                actions.SetCurrentTeam(teamId);
            } else {
                actions.SetFirstTeamAsCurrent();
            }
            await actions.GetAllEmployees();
            setReady(true);
        };

        loadData();
    }, [actions, state.alwaysNull, teamId]);

    const getInitials = (name: string): string => {
        const parts: Array<string> = name.split(' ');
        let initials = '';
        initials += parts[0].slice(0, 1);
        initials += parts[parts.length - 1].slice(0, 1);

        return initials;
    };

    const showAddTeamMember = () => {
        setShowAddTeamMemberDialog(true);
    };

    const hideAddTeamMember = () => {
        setShowAddTeamMemberDialog(false);
    };

    const currentTeam = CurrentTeam(state);
    const currentUserIsAdmin = CurrentUserIsAdmin(state);
    let teamMembers = new Array<Person>();
    if (currentTeam && currentTeam.people) {
        teamMembers = currentTeam.people
            .map((teamMember) => {
                const employee = SpecificEmployee(state, teamMember.employeeId);
                return {
                    id: `${employee?.id}`,
                    name: `${employee?.firstName} ${employee?.lastName}`,
                    role: teamMember.role,
                    allocation: teamMember.allocation,
                };
            })
            .sort((a: TeamMemberViewModel, b: TeamMemberViewModel) => {
                return a.name.localeCompare(b.name);
            });
    }

    return (
        <PageContainer activePage="people">
            <Box
                sx={{
                    height: '100%',
                }}
            >
                {ready ? (
                    <React.Fragment>
                        {currentUserIsAdmin ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    width: '100%',
                                    marginBottom: theme.spacing(2),
                                }}
                            >
                                <Button color="primary" variant="contained" onClick={showAddTeamMember}>
                                    Add Team Member
                                </Button>
                                <TeamMemberDialog open={showAddTeamMemberDialog} onClose={hideAddTeamMember} />
                            </Box>
                        ) : null}
                        {teamMembers.length ? (
                            <TableContainer component={Paper}>
                                <Table aria-label="scope-change-table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Role</TableCell>
                                            {hideExcessInfo ? null : <TableCell>Allocation</TableCell>}
                                            <TableCell width={64}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {teamMembers.map((member, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Avatar
                                                            alt={member.name}
                                                            sx={{
                                                                height: '36px',
                                                                width: '36px',
                                                            }}
                                                        >
                                                            {getInitials(member.name)}
                                                        </Avatar>
                                                        <Typography
                                                            sx={{
                                                                marginLeft: theme.spacing(2),
                                                            }}
                                                        >
                                                            {member.name}
                                                        </Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell>{member.role}</TableCell>
                                                {hideExcessInfo ? null : <TableCell>{member.allocation}</TableCell>}
                                                <TableCell
                                                    width={64}
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-end',
                                                    }}
                                                >
                                                    <TeamMemberOptionsMenu teamMember={member} />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <EmptyView
                                primaryText="No team members found"
                                secondaryText="Ask your team lead to set this up"
                                icon={<PersonOutlineIcon />}
                            />
                        )}
                    </React.Fragment>
                ) : (
                    <div>Loading...</div>
                )}
            </Box>
        </PageContainer>
    );
};

export default PeoplePage;
