import React from 'react';
import SingleValueMetric from './SingleValueMetric';
import ChartContainer from '../components/ChartContainer';
import SingleValueChartLoader from '../components/SingleValueChartLoader';
import { Nullable } from '@aviture/interfaces';
import { FilterInfo } from '../store/State';

interface Props {
    data: Nullable<number>;
    filter: FilterInfo;
}

const AverageHitRate: React.FC<Props> = (props) => {
    return (
        <ChartContainer
            header={`Average Hit Rate (${props.filter.label})`}
            loading={false}
            size="small"
            loader={<SingleValueChartLoader />}
        >
            <SingleValueMetric value={props.data} symbol={`%`} />
        </ChartContainer>
    );
};

export default AverageHitRate;
