import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import SubNavigation from '../../components/SubNavigation';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTheme } from '@mui/material/styles';
import useGlobal from '../../store';

interface Props {
    activePage: string;
    children: unknown;
}

const PageContainer: React.FC<Props> = (props) => {
    const theme = useTheme();
    const [state, actions] = useGlobal();

    useEffect(() => {
        const loadData = async () => {
            await actions.SetActiveSection('admin');
        };

        loadData();
    }, [actions, state.alwaysNull]);

    const pages = [
        {
            to: `/admin`,
            id: 'general',
            icon: <SettingsIcon />,
            text: 'General',
        },
        {
            to: `/admin/teams`,
            id: 'teams',
            icon: <DeveloperBoardIcon />,
            text: 'Teams',
        },
        {
            to: `/admin/employees`,
            id: 'employees',
            icon: <PersonIcon />,
            text: 'Employees',
        },
    ];

    return (
        <Box
            sx={{
                display: 'flex',
                height: '100%',
            }}
        >
            <SubNavigation activePage={props.activePage} pages={pages} />
            <Box
                sx={{
                    height: '100%',
                    width: '100%',
                    overflowY: 'auto',
                    margin: theme.spacing(2),
                }}
            >
                {props.children}
            </Box>
        </Box>
    );
};

export default PageContainer;
