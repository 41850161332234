import { State } from '../../store/State';
import { AllSprints } from './AllSprints';
import { CurrentSprintId } from './CurrentSprintId';
import { Nullable, Sprint } from '@aviture/interfaces';

export const PreviousSprint = (state: State): Nullable<Sprint> => {
    const allSprints = AllSprints(state);
    const selectedSprintId = CurrentSprintId(state);

    if (selectedSprintId !== null && allSprints !== null) {
        const selectedSprintIndex = allSprints.findIndex((sprint) => {
            return sprint.id === selectedSprintId;
        });

        if (selectedSprintIndex > 0) {
            return allSprints[selectedSprintIndex - 1];
        }
    }

    return null;
};
