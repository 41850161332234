import { Team } from '@aviture/interfaces';

export class TeamStatusComparator {
    private team: Team;
    constructor(team: Team) {
        this.team = team;
    }

    compare = (a: string, b: string): number => {
        const statuses = this.team.statuses.map((status) => {
            return status.trim().toLowerCase();
        });

        const aIndex = statuses.indexOf(a.trim().toLowerCase());
        const bIndex = statuses.indexOf(b.trim().toLowerCase());

        if (aIndex < bIndex) {
            return -1;
        } else if (aIndex > bIndex) {
            return 1;
        } else {
            return 0;
        }
    }
}