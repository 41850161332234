import { Nullable, Sprint, Team } from '@aviture/interfaces';
import { TeamStatusComparator } from '../../../comparators/TeamStatusComparator';
import { TimeInStatus } from '../../../interfaces/TimeInStatus';

export const GetTimeInStatus = (sprintData: Nullable<Sprint>, currentTeam: Nullable<Team>): TimeInStatus[] => {
    if (sprintData === null || currentTeam === null) {
        return [];
    }

    const timeInStatus = sprintData.metrics.predictability.timeInStatus;
    if (timeInStatus) {
        const teamStatusComparator = new TeamStatusComparator(currentTeam);
        return Object.keys(timeInStatus)
            .sort(teamStatusComparator.compare)
            .map((key) => {
                return {
                    name: key,
                    metric: timeInStatus[key],
                };
            });
    }

    return [];
};
