import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useGlobal from '../../store';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import PageContainer from './PageContainer';
import Grid from '@mui/material/Grid';
import { CurrentDashboardFilter } from '../../selectors/ui/CurrentDashboardFilter';
import { DashboardReady } from '../../selectors/ui/DashboardReady';
import DashboardFilter from '../../components/DashboardFilter';
import { AverageTimeInStatusChartData } from '../../selectors/predictability/chart/AverageTimeInStatusChartData';
import AverageTimeInStatus from '../../metrics/AverageTimeInStatus';

const PredictabilityPage: React.FC = () => {
    const theme = useTheme();
    const { teamId } = useParams();
    const [state, actions] = useGlobal();

    useEffect(() => {
        const loadData = async () => {
            if (teamId) {
                actions.SetCurrentTeam(teamId);
            } else {
                actions.SetFirstTeamAsCurrent();
            }
            actions.SetDashboardReady(true);
        };

        loadData();
    }, [actions, state.alwaysNull, teamId]);

    return (
        <PageContainer activePage="predictability">
            <Box>
                {DashboardReady(state) ? (
                    <React.Fragment>
                        <Box
                            sx={{
                                marginTop: theme.spacing(2),
                                marginBottom: theme.spacing(3),
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <DashboardFilter />
                        </Box>
                        <Grid
                            container
                            spacing={3}
                            sx={{
                                paddingBottom: theme.spacing(2),
                            }}
                        >
                            <Grid item xs={12} md={6} xl={4}>
                                <AverageTimeInStatus
                                    data={AverageTimeInStatusChartData(state)}
                                    filter={CurrentDashboardFilter(state)}
                                />
                            </Grid>
                        </Grid>
                    </React.Fragment>
                ) : (
                    <div>Loading...</div>
                )}
            </Box>
        </PageContainer>
    );
};

export default PredictabilityPage;
