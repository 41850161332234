import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import ActivateTeamDialog from './ActivateTeamDialog';
import { Team } from '@aviture/interfaces';

interface Props {
    onClick: () => void;
    team: Team;
}

const ActivateTeamMenuItem: React.FC<Props> = (props) => {
    const [showDialog, setShowDialog] = useState<boolean>(false);

    const showActivateTeamDialog = () => {
        props.onClick();
        setShowDialog(true);
    };

    const hideActivateTeamDialog = () => {
        setShowDialog(false);
    };

    return (
        <React.Fragment>
            <React.Fragment>
                <MenuItem onClick={showActivateTeamDialog}>Re-Activate</MenuItem>
                <ActivateTeamDialog
                    teamId={props.team.id as string}
                    teamName={props.team.name}
                    onClose={hideActivateTeamDialog}
                    open={showDialog}
                />
            </React.Fragment>
        </React.Fragment>
    );
};

export default ActivateTeamMenuItem;
