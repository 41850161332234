import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Box, Paper } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import PrimaryNavigation from './components/PrimaryNavigation';
import Instruction from './components/Instruction';
import CodePage from './pages/teams/CodePage';
import KpiPage from './pages/teams/KpiPage';
import PeoplePage from './pages/teams/PeoplePage';
import PredictabilityPage from './pages/teams/PredictabilityPage';
import ProductivityPage from './pages/teams/ProductivityPage';
import QualityPage from './pages/teams/QualityPage';
import SettingsPage from './pages/teams/SettingsPage';
import SprintsPage from './pages/teams/SprintsPage';
import TeamsPage from './pages/teams/TeamsPage';
import CreateEmployeePage from './pages/admin/CreateEmployeePage';
import CreateTeamPage from './pages/admin/CreateTeamPage';
import EmployeesAdminPage from './pages/admin/EmployeesAdminPage';
import GeneralAdminPage from './pages/admin/GeneralAdminPage';
import TeamsAdminPage from './pages/admin/TeamsAdminPage';
import OrganizationPage from './pages/organization/OrganizationPage';
import OrganizationKpiPage from './pages/organization/KpiPage';
import ProfilePage from './pages/ProfilePage';
import useGlobal from './store';
import theme from './theme';
import { CurrentAlertMessage } from './selectors/alert/CurrentAlertMessage';
import { CurrentAlertSeverity } from './selectors/alert/CurrentAlertSeverity';
import { CurrentTeam } from './selectors/team/CurrentTeam';
import { Teams } from './selectors/team/Teams';
import { CurrentUser } from './selectors/user/CurrentUser';
import { EnabledActionsForCurrentUser } from './selectors/feature-toggles/EnabledActionsForCurrentUser';
import { EnabledSectionsForCurrentUser } from './selectors/feature-toggles/EnabledSectionsForCurrentUser';
import { EnabledTeamPagesForCurrentUser } from './selectors/feature-toggles/EnabledTeamPagesForCurrentUser';
import { ActiveSection } from './selectors/ui/ActiveSection';
import { AppLoading } from './selectors/ui/AppLoading';
import { AppReady } from './selectors/ui/AppReady';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import LoadingIndicator from './components/LoadingIndicator';
import SignInHeader from './components/SignInHeader';
import ForgotPasswordHeader from './components/ForgotPasswordHeader';
import ConfirmResetPasswordHeader from './components/ConfirmResetPasswordHeader';
import AvitureLogo from './components/AvitureLogo';

const App = () => {
    const [state, actions] = useGlobal();

    useEffect(() => {
        const loadData = async () => {
            await Promise.all([actions.GetAllTeams(), actions.GetMetadata()]);
            const currentUserPoolUser = await Auth.currentUserInfo();
            await actions.GetCurrentUser(currentUserPoolUser);
            actions.SetReady(true);
        };

        loadData();
    }, [actions]);

    const clearAlert = () => {
        actions.ClearAlert();
    };

    const toggleShowInactive = (newValue: boolean) => {
        actions.SetShowInactiveTeams(newValue);
    };

    return (
        <MuiThemeProvider theme={theme}>
            <Router>
                {AppReady(state) ? (
                    <React.Fragment>
                        <PrimaryNavigation
                            user={CurrentUser(state)}
                            teams={Teams(state)}
                            currentTeam={CurrentTeam(state)}
                            enabledPages={EnabledTeamPagesForCurrentUser(state)}
                            enabledSections={EnabledSectionsForCurrentUser(state)}
                            enabledActions={EnabledActionsForCurrentUser(state)}
                            activeSection={ActiveSection(state)}
                            onShowInactive={toggleShowInactive}
                        />
                        <Instruction />
                        <Box
                            sx={{
                                backgroundColor: '#f0f0f0',
                                position: 'absolute',
                                top: '64px',
                                bottom: '0',
                                left: '0',
                                right: '0',
                                overflow: 'hidden',
                            }}
                        >
                            <Paper
                                elevation={0}
                                sx={{
                                    backgroundColor: '#f0f0f0',
                                    height: '100%',
                                }}
                            >
                                <Routes>
                                    <Route
                                        path="/"
                                        element={<TeamsPage enabledPages={EnabledTeamPagesForCurrentUser(state)} />}
                                    />
                                    <Route path="/profile" element={<ProfilePage />} />
                                    <Route path="/teams/:teamId/sprints" element={<SprintsPage />} />
                                    <Route path="/teams/:teamId/people" element={<PeoplePage />} />
                                    <Route path="/teams/:teamId/quality" element={<QualityPage />} />
                                    <Route path="/teams/:teamId/productivity" element={<ProductivityPage />} />
                                    <Route path="/teams/:teamId/predictability" element={<PredictabilityPage />} />
                                    <Route path="/teams/:teamId/code" element={<CodePage />} />
                                    <Route path="/teams/:teamId/kpi" element={<KpiPage />} />
                                    <Route path="/teams/:teamId/settings" element={<SettingsPage />} />
                                    <Route path="/admin/teams" element={<TeamsAdminPage />} />
                                    <Route path="/admin/teams/create" element={<CreateTeamPage />} />
                                    <Route path="/admin/employees/create" element={<CreateEmployeePage />} />
                                    <Route path="/admin/employees" element={<EmployeesAdminPage />} />
                                    <Route path="/organization/kpi" element={<OrganizationKpiPage />} />
                                    <Route
                                        path="/teams"
                                        element={<TeamsPage enabledPages={EnabledTeamPagesForCurrentUser(state)} />}
                                    />
                                    <Route path="/admin" element={<GeneralAdminPage />} />
                                    <Route path="/organization" element={<OrganizationPage />} />
                                </Routes>
                            </Paper>
                        </Box>
                        <Snackbar
                            open={!!CurrentAlertMessage(state)}
                            autoHideDuration={5000}
                            onClose={clearAlert}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <Alert onClose={clearAlert} severity={CurrentAlertSeverity(state)}>
                                {CurrentAlertMessage(state)}
                            </Alert>
                        </Snackbar>
                    </React.Fragment>
                ) : null}
                <Dialog open={AppLoading(state)}>
                    <LoadingIndicator />
                </Dialog>
            </Router>
        </MuiThemeProvider>
    );
};

export default withAuthenticator(App, {
    hideSignUp: true,
    components: {
        Header: AvitureLogo,
        SignIn: {
            Header: SignInHeader,
        },
        ResetPassword: {
            Header: ForgotPasswordHeader,
        },
        ConfirmResetPassword: {
            Header: ConfirmResetPasswordHeader,
        },
    },
});
