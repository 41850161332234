import React, { useEffect } from 'react';
import useGlobal from '../../store';
import { useNavigate } from 'react-router-dom';

const OrganizationPage: React.FC = () => {
    const [state, actions] = useGlobal();
    const navigate = useNavigate();

    useEffect(() => {
        const loadData = async () => {
            navigate(`/organization/kpi`);
        };

        loadData();
    }, [actions, state.alwaysNull, navigate]);

    return <div>Loading...</div>;
};

export default OrganizationPage;
