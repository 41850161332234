import { Store } from 'use-global-hook';
import { State } from '../store/State';
import { API } from 'aws-amplify';
import { CurrentTeam } from '../selectors/team/CurrentTeam';
import { GetAllTeams } from './GetAllTeams';
import { SetAppLoading } from './SetAppLoading';
import { TeamMember } from '@aviture/interfaces';
import { ShowInactiveTeams } from '../selectors/ui/ShowInactiveTeams';

export const UpdateTeamMember = async (store: Store<State, unknown>, data: TeamMember): Promise<void> => {
    const currentTeam = CurrentTeam(store.state);

    if (currentTeam !== null && data !== null) {
        if (currentTeam.people) {
            currentTeam.people = currentTeam.people.filter((person) => {
                return person.employeeId !== data.employeeId;
            });
            currentTeam.people.push(data);
        } else {
            currentTeam.people = new Array<TeamMember>();
            currentTeam.people.push(data);
        }

        const init = {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            body: currentTeam,
        };

        SetAppLoading(store, true);
        await API.put('JiraMetricsApi', '/teams/updateTeam', init);
        await GetAllTeams(store, ShowInactiveTeams(store.state));
        SetAppLoading(store, false);
    }
};
