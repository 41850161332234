import React from 'react';
import Alert from '@mui/material/Alert';
import { CurrentInstruction } from '../selectors/ui/CurrentInstruction';
import useGlobal from '../store';

const Instruction: React.FC = () => {
    const [state] = useGlobal();

    return (
        <React.Fragment>
            {CurrentInstruction(state) ? (
                <Alert
                    severity="info"
                    variant="filled"
                    sx={{
                        borderRadius: 0,
                    }}
                >
                    {CurrentInstruction(state)}
                </Alert>
            ) : null}
        </React.Fragment>
    );
};

export default Instruction;
