import { SprintVelocities } from '../SprintVelocities';
import { ChartData } from '../../../interfaces/ChartData';
import { Nullable } from '@aviture/interfaces';
import { State } from '../../../store/State';
import { CalculateHitRate } from '../../../math/CalculateHitRate';

export const TeamHitRateChartData = (state: State): Nullable<ChartData> => {
    const sprintVelocities = SprintVelocities(state);

    if (sprintVelocities) {
        const metric: ChartData = [['Sprint', 'Hit Rate', 'Ideal']];

        sprintVelocities.forEach((sprintVelocity) => {
            let hitRate = 0;
            if (sprintVelocity && sprintVelocity.committed && sprintVelocity.actual) {
                hitRate = CalculateHitRate(sprintVelocity.committed, sprintVelocity.actual);
            }
            metric.push([sprintVelocity.name, hitRate, 100]);
        });

        return metric;
    }

    return null;
};
