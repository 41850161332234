import { Store } from 'use-global-hook';
import { State, FilterInfo } from '../store/State';

export const SetDashboardFilter = (store: Store<State, unknown>, filter: FilterInfo): void => {
    const ui = { ...store.state.ui };
    ui.dashboard.filter = filter;
    store.setState({
        ...store.state,
        ui,
    });
};
