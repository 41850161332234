import { Store } from 'use-global-hook';
import { State } from '../store/State';
import { User } from '@aviture/interfaces';
import { API } from 'aws-amplify';

export const CreateUser = async (store: Store<State, unknown>, user: User): Promise<void> => {
    const init = {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        body: user,
    };

    try {
        const responseBody = await API.post('JiraMetricsApi', '/users/createUser', init);
        const user: User = responseBody.user as User;

        store.setState({
            ...store.state,
            user,
        });
    } catch (err) {
        console.log(`An error occurred ${JSON.stringify(err)}`);
    }
};
