import { Store } from 'use-global-hook';
import { State } from '../store/State';
import { FirstTeam } from '../selectors/team/FirstTeam';
import { SetCurrentTeam } from './SetCurrentTeam';

export const SetFirstTeamAsCurrent = (store: Store<State, unknown>): void => {
    const firstTeam = FirstTeam(store.state);
    if (firstTeam !== null) {
        SetCurrentTeam(store, firstTeam.id);
    }
};
