import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteEmployeeMenuItem from './DeleteEmployeeMenuItem';
import { Employee } from '@aviture/interfaces';

interface Props {
    employee: Employee;
}

const EmployeesOptionMenu: React.FC<Props> = (props) => {
    const [menuEl, setMenuEl] = useState(null);

    const handleMenuClick = (event: any) => {
        setMenuEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuEl(null);
    };

    return (
        <React.Fragment>
            <IconButton aria-label="options" onClick={handleMenuClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                id={`employee-${props.employee.id}-menu`}
                anchorEl={menuEl}
                keepMounted
                open={Boolean(menuEl)}
                onClose={handleMenuClose}
            >
                <DeleteEmployeeMenuItem onClick={handleMenuClose} employee={props.employee} />
            </Menu>
        </React.Fragment>
    );
};

export default EmployeesOptionMenu;
