import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

const ConfirmResetPasswordHeader = () => {
    const theme = useTheme();

    return (
        <Box>
            <Typography variant="h5">Reset Your Password</Typography>
            <Typography variant="subtitle2" sx={{
                marginTop: '16px'
            }}>
                Enter the code you received, plus a new password, in the form below.
            </Typography>
        </Box>
    );
};

export default ConfirmResetPasswordHeader;
