import { Store } from 'use-global-hook';
import { AlertSeverity, State } from '../store/State';

export const FireAlert = (store: Store<State, unknown>, message: string, severity: AlertSeverity): void => {
    store.setState({
        ...store.state,
        alert: {
            message,
            severity,
        },
    });
};
