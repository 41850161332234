import { State } from '../../store/State';
import { Nullable, Sprint } from '@aviture/interfaces';
import { CurrentTeam } from '../team/CurrentTeam';
import { SprintStartDateComparator } from '../../comparators/SprintStartDateComparator';

export const AllSprints = (state: State): Nullable<Sprint[]> => {
    const currentTeam = CurrentTeam(state);

    if (currentTeam && currentTeam.sprints) {
        const sprints = currentTeam.sprints;

        return sprints.sort(SprintStartDateComparator);
    }

    return null;
};
