import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Team } from '@aviture/interfaces';
import ActivateTeamMenuItem from './ActivateTeamMenuItem';
import DeactivateTeamMenuItem from './DeactivateTeamMenuItem';

interface Props {
    team: Team;
}

const TeamsOptionMenu: React.FC<Props> = (props) => {
    const [menuEl, setMenuEl] = useState(null);

    const handleMenuClick = (event: any) => {
        setMenuEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuEl(null);
    };

    return (
        <React.Fragment>
            <IconButton aria-label="options" onClick={handleMenuClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                id={`team-${props.team.id}-menu`}
                anchorEl={menuEl}
                keepMounted
                open={Boolean(menuEl)}
                onClose={handleMenuClose}
            >
                {props.team.active ? (
                    <DeactivateTeamMenuItem onClick={handleMenuClose} team={props.team} />
                ) : (
                    <ActivateTeamMenuItem onClick={handleMenuClose} team={props.team} />
                )}
            </Menu>
        </React.Fragment>
    );
};

export default TeamsOptionMenu;
