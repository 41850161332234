import { Store } from 'use-global-hook';
import { State } from '../store/State';
import { Employee } from '@aviture/interfaces';
import { API } from 'aws-amplify';
import { SetAppLoading } from './SetAppLoading';

export const CreateEmployee = async (store: Store<State, unknown>, employee: Employee): Promise<void> => {
    const init = {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        body: employee,
    };
    const forms = store.state.forms;

    try {
        SetAppLoading(store, true);

        forms.createEmployee.submitting = true;
        store.setState({
            ...store.state,
            forms,
        });
        const responseBody = await API.post('JiraMetricsApi', '/employees/createEmployee', init);

        forms.createEmployee = {
            error: false,
            response: responseBody as Employee,
            submitted: true,
            submitting: false,
        };
        store.setState({
            ...store.state,
            forms,
        });

        SetAppLoading(store, false);
    } catch (err) {
        console.log(`An error occurred ${JSON.stringify(err)}`);
        forms.createEmployee = {
            error: true,
            response: null,
            submitted: true,
            submitting: false,
        };
        store.setState({
            ...store.state,
            forms,
        });

        SetAppLoading(store, false);
    }
};
