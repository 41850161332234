import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useGlobal from '../../store';
import BarSparkChartKpi from '../../components/BarSparkChartKpi';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { CurrentTeam } from '../../selectors/team/CurrentTeam';
import { EnabledActionsForCurrentUser } from '../../selectors/feature-toggles/EnabledActionsForCurrentUser';
import DeleteSprintDialog from '../../components/DeleteSprintDialog';
import Divider from '@mui/material/Divider';
import EmptyView from '../../components/EmptyView';
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '@mui/material/styles';
import PageContainer from './PageContainer';
import { CreatedDefectsBreakdownSparkChartData } from '../../selectors/sprint/metrics/chart/CreatedDefectsBreakdownSparkChartData';
import { ResolvedDefectsBreakdownSparkChartData } from '../../selectors/sprint/metrics/chart/ResolvedDefectsBreakdownSparkChartData';
import { Velocity } from '../../selectors/sprint/metrics/Velocity';
import { PreviousVelocity } from '../../selectors/sprint/metrics/PreviousVelocity';
import { Commitment } from '../../selectors/sprint/metrics/Commitment';
import { PreviousCommitment } from '../../selectors/sprint/metrics/PreviousCommitment';
import { HitRate } from '../../selectors/sprint/metrics/HitRate';
import { PreviousHitRate } from '../../selectors/sprint/metrics/PreviousHitRate';
import { TimeInStatus } from '../../selectors/sprint/metrics/TimeInStatus';
import { PreviousTimeInStatus } from '../../selectors/sprint/metrics/PreviousTimeInStatus';
import { CreatedDefectsCount } from '../../selectors/sprint/metrics/CreatedDefectsCount';
import { PreviousCreatedDefectsCount } from '../../selectors/sprint/metrics/PreviousCreatedDefectsCount';
import { ResolvedDefectsCount } from '../../selectors/sprint/metrics/ResolvedDefectsCount';
import { PreviousResolvedDefectsCount } from '../../selectors/sprint/metrics/PreviousResolvedDefectsCount';
import { CurrentSprint } from '../../selectors/sprint/CurrentSprint';
import SingleValueKpi from '../../components/SingleValueKpi';
import SprintDialog from '../../components/SprintDialog';
import SprintFilter from '../../components/SprintFilter';
import SprintGoalAchievement from '../../components/SprintGoalAchievement';
import { SprintViewModel } from '../../interfaces/SprintViewModel';
import Typography from '@mui/material/Typography';
import format from 'date-fns/format';
import { SpecificTeam } from '../../selectors/team/SpecificTeam';
import { useNavigate } from 'react-router-dom';

const SprintsPage: React.FC = () => {
    const { teamId } = useParams();
    const [state, actions] = useGlobal();
    const navigate = useNavigate();
    const theme = useTheme();
    const [showAddSprintDialog, setShowAddSprintDialog] = useState<boolean>(false);
    const [showEditSprintDialog, setShowEditSprintDialog] = useState<boolean>(false);
    const [showDeleteSprintDialog, setShowDeleteSprintDialog] = useState<boolean>(false);

    useEffect(() => {
        const loadData = async () => {
            if (!state.ui.appLoading) {
                if (teamId) {
                    const team = SpecificTeam(state, teamId);

                    if (team) {
                        actions.SetCurrentTeam(teamId);
                    } else {
                        actions.SetFirstTeamAsCurrent();
                        navigate('/teams');
                    }
                } else {
                    actions.SetFirstTeamAsCurrent();
                }
                actions.SetDashboardReady(true);
            }
        };

        loadData();
    }, [actions, state.alwaysNull, state.teams, teamId]);

    const handleSprintChange = async (sprintId: string) => {
        actions.SetCurrentSprintId(sprintId);
    };

    const addSprint = async (viewModel: SprintViewModel) => {
        setShowAddSprintDialog(false);
        await actions.AddSprint(viewModel);
    };

    const editSprint = async (viewModel: SprintViewModel) => {
        setShowEditSprintDialog(false);
        await actions.EditSprint(viewModel);
    };

    const deleteSprint = async (sprintId: string) => {
        setShowDeleteSprintDialog(false);
        await actions.DeleteSprint(sprintId);
    };

    const showAddSprint = () => {
        setShowAddSprintDialog(true);
    };

    const hideAddSprint = () => {
        setShowAddSprintDialog(false);
    };

    const showEditSprint = () => {
        setShowEditSprintDialog(true);
    };

    const hideEditSprint = () => {
        setShowEditSprintDialog(false);
    };

    const showDeleteSprint = () => {
        setShowDeleteSprintDialog(true);
    };

    const hideDeleteSprint = () => {
        setShowDeleteSprintDialog(false);
    };

    const currentTeam = CurrentTeam(state);
    const selectedSprint = CurrentSprint(state);
    const velocityActual = Velocity(state);
    const previousVelocityActual = PreviousVelocity(state);
    const velocityCommitted = Commitment(state);
    const previousVelocityCommitted = PreviousCommitment(state);
    const hitRate = HitRate(state);
    const previousHitRate = PreviousHitRate(state);
    const createdDefects = CreatedDefectsCount(state);
    const previousCreatedDefects = PreviousCreatedDefectsCount(state);
    const resolvedDefects = ResolvedDefectsCount(state);
    const previousResolvedDefects = PreviousResolvedDefectsCount(state);
    const timeInStatus = TimeInStatus(state);
    const previousTimeInStatus = PreviousTimeInStatus(state);
    const createdDefectsBreakdownChartData = CreatedDefectsBreakdownSparkChartData(state);
    const resolvedDefectsBreakdownChartData = ResolvedDefectsBreakdownSparkChartData(state);
    const enabledActions = EnabledActionsForCurrentUser(state);

    return (
        <PageContainer activePage="sprints">
            <Box
                sx={{
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(2),
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                {currentTeam !== null && currentTeam.sprints && currentTeam.sprints.length > 0 ? (
                    <SprintFilter onChange={handleSprintChange} selected={selectedSprint ? selectedSprint.id : null} />
                ) : (
                    <Box></Box>
                )}
                {enabledActions.indexOf('crud-sprints') > -1 ? (
                    <Box>
                        {selectedSprint ? (
                            <React.Fragment>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: theme.palette.error.main,
                                        color: theme.palette.common.white,
                                        '&:hover': {
                                            backgroundColor: theme.palette.error.dark,
                                        },
                                        marginRight: theme.spacing(2),
                                    }}
                                    onClick={showDeleteSprint}
                                >
                                    Delete Sprint
                                </Button>
                                <DeleteSprintDialog
                                    open={showDeleteSprintDialog}
                                    onClose={hideDeleteSprint}
                                    onSubmit={deleteSprint}
                                    sprintId={selectedSprint.id}
                                    sprintName={selectedSprint.name}
                                />
                            </React.Fragment>
                        ) : undefined}
                        {selectedSprint ? (
                            <React.Fragment>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    sx={{
                                        marginRight: theme.spacing(2),
                                    }}
                                    onClick={showEditSprint}
                                >
                                    Edit Sprint
                                </Button>
                                <SprintDialog
                                    open={showEditSprintDialog}
                                    onClose={hideEditSprint}
                                    onSubmit={editSprint}
                                    team={currentTeam}
                                    sprint={selectedSprint}
                                />
                            </React.Fragment>
                        ) : undefined}
                        <Button variant="contained" color="primary" onClick={showAddSprint}>
                            Add Sprint
                        </Button>
                        <SprintDialog
                            open={showAddSprintDialog}
                            onClose={hideAddSprint}
                            onSubmit={addSprint}
                            team={currentTeam}
                        />
                    </Box>
                ) : undefined}
            </Box>
            <Box>
                {selectedSprint ? (
                    <React.Fragment>
                        <Box
                            sx={{
                                marginBottom: theme.spacing(3),
                            }}
                        >
                            <Typography
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: theme.spacing(1),
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: theme.typography.fontWeightBold,
                                        flexShrink: 0,
                                    }}
                                    component="span"
                                >
                                    Date
                                </Typography>
                                &nbsp;-&nbsp;
                                {format(new Date(selectedSprint.startDate as string), 'MMMM d, yyyy')}
                                &nbsp;-&nbsp;
                                {format(new Date(selectedSprint.endDate as string), 'MMMM d, yyyy')}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: theme.spacing(1),
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        width: '100%',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: theme.typography.fontWeightBold,
                                            flexShrink: 0,
                                        }}
                                        component="span"
                                    >
                                        Sprint Goal
                                    </Typography>
                                    &nbsp;-&nbsp;
                                    {selectedSprint.goal.description ? (
                                        <Box
                                            sx={{
                                                width: '100%',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                }}
                                            >
                                                <Typography>{selectedSprint.goal.description}</Typography>
                                            </Box>
                                        </Box>
                                    ) : (
                                        <Typography>No goal defined for this sprint</Typography>
                                    )}
                                </Box>
                            </Box>
                            <SprintGoalAchievement
                                goal={selectedSprint.goal.description}
                                goalAchieved={selectedSprint.goal.achieved}
                                rationale={selectedSprint.goal.rationale}
                                sprintId={selectedSprint.id}
                            />
                        </Box>
                        <Card
                            sx={{
                                marginBottom: theme.spacing(3),
                            }}
                        >
                            <CardHeader title="Productivity" />
                            <CardContent>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    <SingleValueKpi
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flexGrow: 1,
                                        }}
                                        primaryLabel="Velocity"
                                        metric={velocityActual}
                                        trend={{
                                            previous: previousVelocityActual,
                                        }}
                                    />
                                    <Divider orientation="vertical" flexItem />
                                    <SingleValueKpi
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flexGrow: 1,
                                        }}
                                        primaryLabel="Committed"
                                        metric={velocityCommitted}
                                        trend={{
                                            previous: previousVelocityCommitted,
                                        }}
                                    />
                                    <Divider orientation="vertical" flexItem />
                                    <SingleValueKpi
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flexGrow: 1,
                                        }}
                                        primaryLabel="Hit Rate"
                                        metric={hitRate}
                                        unit={'%'}
                                        trend={{
                                            previous: previousHitRate,
                                        }}
                                    />
                                </Box>
                            </CardContent>
                        </Card>
                        <Card
                            sx={{
                                marginBottom: theme.spacing(3),
                            }}
                        >
                            <CardHeader title="Process Efficiency" />
                            <CardContent>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    {timeInStatus.length ? (
                                        <React.Fragment>
                                            {timeInStatus.map((item, index) => (
                                                <React.Fragment key={`efficiency-${index}`}>
                                                    <Box
                                                        sx={{
                                                            flexGrow: 1,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            marginBottom: theme.spacing(3),
                                                        }}
                                                        key={`efficiency-${index}`}
                                                    >
                                                        <SingleValueKpi
                                                            sx={{
                                                                width: '100%',
                                                            }}
                                                            primaryLabel={item.name}
                                                            metric={item.metric}
                                                            subtitle="Days"
                                                            trend={{
                                                                previous: previousTimeInStatus[index]
                                                                    ? previousTimeInStatus[index].metric
                                                                    : undefined,
                                                                invertColorOnly: true,
                                                            }}
                                                        />
                                                    </Box>
                                                    {index < Object.keys(timeInStatus).length - 1 ? (
                                                        <Divider orientation="vertical" flexItem />
                                                    ) : null}
                                                </React.Fragment>
                                            ))}
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>No Data</React.Fragment>
                                    )}
                                </Box>
                            </CardContent>
                        </Card>
                        <Card
                            sx={{
                                marginBottom: theme.spacing(3),
                            }}
                        >
                            <CardHeader title="Quality" />
                            <CardContent>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    <SingleValueKpi
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flexGrow: 1,
                                        }}
                                        primaryLabel="New Defects"
                                        metric={createdDefects}
                                        trend={{
                                            previous: previousCreatedDefects,
                                        }}
                                    />
                                    <Divider orientation="vertical" flexItem />
                                    {createdDefectsBreakdownChartData !== null &&
                                    createdDefectsBreakdownChartData.length > 1 ? (
                                        <BarSparkChartKpi
                                            primaryLabel="New Defect Breakdown"
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                flexGrow: 1,
                                            }}
                                            chartData={createdDefectsBreakdownChartData}
                                            chartHeight="150px"
                                            showXAxis
                                        />
                                    ) : (
                                        <SingleValueKpi
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                flexGrow: 1,
                                            }}
                                            primaryLabel="New Defect Breakdown"
                                            metric="N/A"
                                        />
                                    )}
                                    <Divider orientation="vertical" flexItem />
                                    <SingleValueKpi
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flexGrow: 1,
                                        }}
                                        primaryLabel="Resolved Defects"
                                        metric={resolvedDefects}
                                        trend={{
                                            previous: previousResolvedDefects,
                                        }}
                                    />
                                    <Divider orientation="vertical" flexItem />
                                    {resolvedDefectsBreakdownChartData !== null &&
                                    resolvedDefectsBreakdownChartData.length > 1 ? (
                                        <BarSparkChartKpi
                                            primaryLabel="Resolved Defect Breakdown"
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                flexGrow: 1,
                                            }}
                                            chartData={resolvedDefectsBreakdownChartData}
                                            chartHeight="150px"
                                            showXAxis
                                        />
                                    ) : (
                                        <SingleValueKpi
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                flexGrow: 1,
                                            }}
                                            primaryLabel="Resolved Defect Breakdown"
                                            metric="N/A"
                                        />
                                    )}
                                </Box>
                            </CardContent>
                        </Card>
                    </React.Fragment>
                ) : (
                    <EmptyView
                        primaryText="No Sprint Selected"
                        secondaryText="Please select a sprint using the dropdown above, or create a new sprint"
                        icon={<InfoIcon />}
                    />
                )}
            </Box>
        </PageContainer>
    );
};

export default SprintsPage;
