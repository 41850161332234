import React from 'react';
import Box from '@mui/material/Box';
import { Chart } from 'react-google-charts';
import { ChartData } from '../interfaces/ChartData';
import { Nullable } from '@aviture/interfaces';
import { useTheme } from '@mui/material/styles';

interface IProps {
    data: Nullable<ChartData>;
    maxValue?: number;
    height?: string;
    showXAxis?: boolean;
    colors?: Array<string>;
}

const BarSparkChart: React.FC<IProps> = (props) => {
    const theme = useTheme();

    return props.data && props.data.length ? (
        <Box
            sx={{
                textAlign: 'center',
                width: '100%',
            }}
        >
            <Chart
                height={props.height ? props.height : '200px'}
                chartType="ComboChart"
                loader={<Box>Loading...</Box>}
                data={props.data}
                options={{
                    colors: props.colors ? props.colors : ['#4285f4', theme.palette.success.light],
                    legend: {
                        position: 'none',
                    },
                    hAxis: {
                        textPosition: props.showXAxis ? 'out' : 'none',
                    },
                    vAxis: {
                        textPosition: 'none',
                        logScale: false,
                        maxValue: props.maxValue,
                    },
                    axisTitlesPosition: 'none',
                    series: {
                        0: {
                            type: 'bars',
                        },
                        1: {
                            type: 'line',
                        },
                    },
                }}
            />
        </Box>
    ) : (
        <Box>Loading...</Box>
    );
};

export default BarSparkChart;
