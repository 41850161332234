import { Store } from 'use-global-hook';
import { State } from '../store/State';
import { SetAppLoading } from './SetAppLoading';

export const SetReady = (store: Store<State, unknown>, ready: boolean): void => {
    SetAppLoading(store, false);
    const ui = { ...store.state.ui };
    ui.ready = ready;
    store.setState({
        ...store.state,
        ui,
    });
};
