import React from 'react';

const ProfilePage: React.FC = () => {
    return (
        <div>
            <h2>Profile</h2>
        </div>
    );
}

export default ProfilePage;
