import React, { useEffect } from 'react';
import useGlobal from '../../store';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import PageContainer from './PageContainer';
import { CurrentOrganizationFilter } from '../../selectors/ui/CurrentOrganizationFilter';
import { TeamsWithFilteredSprintsByMonthAndYear } from '../../selectors/team/TeamsWithFilteredSprintsByMonthAndYear';
import { AllSprintsForOrganization } from '../../selectors/sprint/AllSprintsForOrganization';
import MonthYearPicker from '../../components/MonthYearPicker';
import { GetAllYearsForSprintEndDates } from '../../selectors/sprint/utils/GetAllYearsForSprintEndDates';
import ProductivityKpiCard from '../../components/ProductivityKpiCard';
import QualityKpiCard from '../../components/QualityKpiCard';

const KpiPage: React.FC = () => {
    const theme = useTheme();
    const [state, actions] = useGlobal();

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        const loadData = async () => {
            await Promise.all([actions.GetAllTeams(), actions.GetMetadata()]);
            actions.SetReady(true);
        };

        loadData();
    }, [actions, state.alwaysNull]);

    const updateSelectedMonth = (month: number) => {
        actions.SetOrganizationFilter({
            month,
            year: CurrentOrganizationFilter(state).year,
        });
    };

    const updateSelectedYear = (year: number) => {
        actions.SetOrganizationFilter({
            month: CurrentOrganizationFilter(state).month,
            year,
        });
    };

    return (
        <PageContainer activePage="kpi">
            <Box>
                <MonthYearPicker
                    month={CurrentOrganizationFilter(state).month}
                    year={CurrentOrganizationFilter(state).year}
                    yearRange={GetAllYearsForSprintEndDates(AllSprintsForOrganization(state))}
                    onChangeMonth={updateSelectedMonth}
                    onChangeYear={updateSelectedYear}
                    sx={{
                        marginTop: theme.spacing(2),
                        marginBottom: theme.spacing(2),
                    }}
                />
                <ProductivityKpiCard
                    teams={TeamsWithFilteredSprintsByMonthAndYear(
                        state,
                        CurrentOrganizationFilter(state).month,
                        CurrentOrganizationFilter(state).year
                    )}
                    sx={{
                        marginBottom: theme.spacing(3),
                    }}
                />
                <QualityKpiCard
                    teams={TeamsWithFilteredSprintsByMonthAndYear(
                        state,
                        CurrentOrganizationFilter(state).month,
                        CurrentOrganizationFilter(state).year
                    )}
                    sx={{
                        marginBottom: theme.spacing(3),
                    }}
                />
            </Box>
        </PageContainer>
    );
};

export default KpiPage;
