import { Store } from 'use-global-hook';
import { OrganizationFilter, State } from '../store/State';

export const SetOrganizationFilter = (store: Store<State, unknown>, filter: OrganizationFilter): void => {
    const ui = { ...store.state.ui };
    ui.organization = filter;
    store.setState({
        ...store.state,
        ui,
    });
};
