import { State } from '../../store/State';
import { Sprint } from '@aviture/interfaces';
import { Teams } from '../team/Teams';
import { SprintEndDateComparator } from '../../comparators/SprintEndDateComparator';

export const AllSprintsForOrganization = (state: State): Sprint[] => {
    const allTeams = Teams(state);
    let allSprints: Array<Sprint> = [];

    if (allTeams && allTeams.length) {
        allTeams.forEach((team) => {
            if (team && team.sprints && team.sprints.length) {
                const uniqueSprints = team.sprints.filter((sprint) => {
                    return allSprints.indexOf(sprint) === -1;
                });
                allSprints = allSprints.concat(uniqueSprints);
            }
        });

        return allSprints.sort(SprintEndDateComparator);
    }

    return allSprints;
};
