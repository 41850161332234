import { Nullable, Sprint } from '@aviture/interfaces';
import { CalculateAverage } from '../../../math/CalculateAverage';
import { GetHitRate } from './GetHitRate';

export const GetHitRateForSprints = (sprints: Array<Nullable<Sprint>>): number => {
    return CalculateAverage(
        sprints.map((sprint) => {
            const hitRate = GetHitRate(sprint);
            if (hitRate) {
                if (hitRate > 100) {
                    return 100;
                }
                return hitRate;
            }

            return 0;
        }),
        2
    );
};
