import React from 'react';
import SingleValueMetric from './SingleValueMetric';
import ChartContainer from '../components/ChartContainer';
import SingleValueChartLoader from '../components/SingleValueChartLoader';
import { Nullable } from '@aviture/interfaces';
import { FilterInfo } from '../store/State';

interface Props {
    data: Nullable<number | string>;
    achieved: number;
    total: number;
    filter: FilterInfo;
}

const SprintGoalHitRate: React.FC<Props> = (props) => {
    return (
        <ChartContainer
            header={`Sprint Goal Hit Rate (${props.filter.label})`}
            loading={false}
            size="small"
            loader={<SingleValueChartLoader />}
        >
            <SingleValueMetric value={props.data} subtitle={props.total > 0 ? `${props.achieved} out of ${props.total} goals achieved` : undefined} symbol={typeof props.data === 'number' ? `%` : ''} />
        </ChartContainer>
    );
};

export default SprintGoalHitRate;
