import { State } from '../../store/State';
import { AllSprints } from './AllSprints';
import { Nullable, Sprint } from '@aviture/interfaces';

export const AllSprintsDescending = (state: State): Nullable<Sprint[]> => {
    const sprints = AllSprints(state);

    if (sprints) {
        return sprints.reverse();
    }

    return null;
};
