import React from 'react';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

interface Props {
    name: string;
    stars: 0 | 1 | 2 | 3 | 4 | 5;
}

const StarRating: React.FC<Props> = (props) => {
    const theme = useTheme();

    const starIcons = new Array<JSX.Element>();
    if (props.stars > 0) {
        for (let i = 0; i < props.stars; i++) {
            starIcons.push(
                <StarIcon
                    sx={{
                        marginRight: theme.spacing(1),
                        color: '#DAA520',
                    }}
                    key={`star-${i - 1}`}
                />
            );
        }
        for (let j = 5 - props.stars; j > 0; j--) {
            starIcons.push(
                <StarBorderIcon
                    sx={{
                        marginRight: theme.spacing(1),
                        color: '#DAA520',
                    }}
                    key={`star-${5 - j}`}
                />
            );
        }
    } else {
        starIcons.push(
            <StarBorderIcon
                sx={{
                    marginRight: theme.spacing(1),
                    color: '#DAA520',
                }}
                key={'star-1'}
            />,
            <StarBorderIcon
                sx={{
                    marginRight: theme.spacing(1),
                    color: '#DAA520',
                }}
                key={'star-2'}
            />,
            <StarBorderIcon
                sx={{
                    marginRight: theme.spacing(1),
                    color: '#DAA520',
                }}
                key={'star-3'}
            />,
            <StarBorderIcon
                sx={{
                    marginRight: theme.spacing(1),
                    color: '#DAA520',
                }}
                key={'star-4'}
            />,
            <StarBorderIcon
                sx={{
                    marginRight: theme.spacing(1),
                    color: '#DAA520',
                }}
                key={'star-5'}
            />
        );
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Typography
                sx={{
                    marginRight: theme.spacing(2),
                    fontWeight: theme.typography.fontWeightBold,
                }}
            >
                {props.name}:
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {starIcons}
            </Box>
        </Box>
    );
};

export default StarRating;
