import { State } from '../../store/State';
import { Nullable, SprintDefectCount } from '@aviture/interfaces';
import { FilteredSprints } from '../sprint/FilteredSprints';

export const CreatedDefectsBySprint = (state: State): Nullable<SprintDefectCount[]> => {
    const sprints = FilteredSprints(state);

    if (sprints && sprints.length) {
        const metric = sprints.map((sprint) => {
            const openDefectsThisSprint = sprint.metrics.quality.newDefectsThisSprint;
            let count = 0;
            if (openDefectsThisSprint) {
                Object.keys(openDefectsThisSprint).forEach((severity) => {
                    count += (openDefectsThisSprint[severity as 'lowest' | 'low' | 'medium' | 'high' | 'urgent'] as number);
                });
            }
            return {
                name: sprint.name,
                count,
            };
        });

        return metric;
    }

    return null;
};
