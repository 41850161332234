import React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import useGlobal from '../store';
import { AllSprintsDescending } from '../selectors/sprint/AllSprintsDescending';
import { Nullable } from '@aviture/interfaces';

interface Props {
    onChange: (sprintId: string) => void;
    selected: Nullable<string>;
}

const SprintFilter: React.FC<Props> = (props) => {
    const [state] = useGlobal();

    const updateSprintId = async (event: any) => {
        await props.onChange(event.target.value);
    };

    const sprints = AllSprintsDescending(state);
    const menuItems = sprints
        ? sprints.map((sprint) => (
              <MenuItem value={sprint.id} key={sprint.id}>
                  {sprint.name}
              </MenuItem>
          ))
        : [];

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <FormControl variant="outlined">
                <InputLabel id="sprint-label">Sprint</InputLabel>
                {sprints ? (
                    <Select
                        id="sprint-menu"
                        sx={{
                            minWidth: '300px',
                        }}
                        value={props.selected ? props.selected : ''}
                        onChange={updateSprintId}
                        label="Sprint"
                    >
                        {menuItems}
                    </Select>
                ) : null}
            </FormControl>
        </Box>
    );
};

export default SprintFilter;
