import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import format from 'date-fns/format';
import { Nullable, Team, Sprint } from '@aviture/interfaces';
import { SprintViewModel } from '../interfaces/SprintViewModel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import parseISO from 'date-fns/parseISO';

interface Props {
    onClose: () => void;
    onSubmit: (viewModel: SprintViewModel) => void;
    open: boolean;
    sprint?: Sprint;
    team: Nullable<Team>;
}

interface TimeInStatus {
    [key: string]: number | string;
}

interface TimeInStatusSubmit {
    [key: string]: number;
}

const SprintDialog: React.FC<Props> = (props) => {
    const [name, setName] = useState<string>(props.sprint?.name || '');
    const [startDate, setStartDate] = useState<string>(props.sprint?.startDate || '');
    const [endDate, setEndDate] = useState<string>(props.sprint?.endDate || '');

    const [goal, setGoal] = useState<string>(props.sprint?.goal?.description || '');
    const [goalAchieved, setGoalAchieved] = useState<boolean>(props.sprint?.goal?.achieved || false);
    const [rationale, setRationale] = useState<string>(props.sprint?.goal?.rationale || '');
    const [trackGoal, setTrackGoal] = useState<boolean>(props.sprint?.goal ? props.sprint.goal.trackGoal : true);

    const [committed, setCommitted] = useState<Nullable<number>>(
        props.sprint?.metrics.productivity.velocity.committed || 0
    );
    const [actual, setActual] = useState<Nullable<number>>(props.sprint?.metrics.productivity.velocity.actual || 0);

    const [openDefectsLowest, setOpenDefectsLowest] = useState<Nullable<number>>(
        props.sprint?.metrics.quality.openDefects.lowest || 0
    );
    const [openDefectsLow, setOpenDefectsLow] = useState<Nullable<number>>(
        props.sprint?.metrics.quality.openDefects.low || 0
    );
    const [openDefectsMedium, setOpenDefectsMedium] = useState<Nullable<number>>(
        props.sprint?.metrics.quality.openDefects.medium || 0
    );
    const [openDefectsHigh, setOpenDefectsHigh] = useState<Nullable<number>>(
        props.sprint?.metrics.quality.openDefects.high || 0
    );
    const [openDefectsUrgent, setOpenDefectsUrgent] = useState<Nullable<number>>(
        props.sprint?.metrics.quality.openDefects.urgent || 0
    );
    const [newDefectsLowest, setNewDefectsLowest] = useState<Nullable<number>>(
        props.sprint?.metrics.quality.newDefectsThisSprint.lowest || 0
    );
    const [newDefectsLow, setNewDefectsLow] = useState<Nullable<number>>(
        props.sprint?.metrics.quality.newDefectsThisSprint.low || 0
    );
    const [newDefectsMedium, setNewDefectsMedium] = useState<Nullable<number>>(
        props.sprint?.metrics.quality.newDefectsThisSprint.medium || 0
    );
    const [newDefectsHigh, setNewDefectsHigh] = useState<Nullable<number>>(
        props.sprint?.metrics.quality.newDefectsThisSprint.high || 0
    );
    const [newDefectsUrgent, setNewDefectsUrgent] = useState<Nullable<number>>(
        props.sprint?.metrics.quality.newDefectsThisSprint.urgent || 0
    );

    const [closedDefectsLowest, setClosedDefectsLowest] = useState<Nullable<number>>(
        props.sprint?.metrics.quality.closedDefectsThisSprint.lowest || 0
    );
    const [closedDefectsLow, setClosedDefectsLow] = useState<Nullable<number>>(
        props.sprint?.metrics.quality.closedDefectsThisSprint.low || 0
    );
    const [closedDefectsMedium, setClosedDefectsMedium] = useState<Nullable<number>>(
        props.sprint?.metrics.quality.closedDefectsThisSprint.medium || 0
    );
    const [closedDefectsHigh, setClosedDefectsHigh] = useState<Nullable<number>>(
        props.sprint?.metrics.quality.closedDefectsThisSprint.high || 0
    );
    const [closedDefectsUrgent, setClosedDefectsUrgent] = useState<Nullable<number>>(
        props.sprint?.metrics.quality.closedDefectsThisSprint.urgent || 0
    );

    const [defaultTimeInStatus, setDefaultTimeInStatus] = useState<TimeInStatus>({});
    const [timeInStatus, setTimeInStatus] = useState<TimeInStatus>(
        props.sprint?.metrics.predictability.timeInStatus || defaultTimeInStatus
    );

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setName(event.target.value as string);
    };

    const handleStartDateChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setStartDate(event.target.value as string);
    };

    const handleEndDateChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setEndDate(event.target.value as string);
    };

    const handleGoalChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setGoal(event.target.value as string);
    };

    const handleGoalAchievedChange = (event: React.ChangeEvent<{ checked: unknown }>) => {
        setGoalAchieved(event.target.checked as boolean);
    };

    const handleRationaleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setRationale(event.target.value as string);
    };

    const handleTrackGoalChange = (event: React.ChangeEvent<{ checked: unknown }>) => {
        const shouldTrackGoal = event.target.checked as boolean;

        // If the sprint doesn't track a goal, clear out any goal information
        setTrackGoal(shouldTrackGoal);
        if (!shouldTrackGoal) {
            setGoal('');
            setGoalAchieved(false);
            setRationale('');
        }
    };

    const handleCommittedChange = (event: React.ChangeEvent<{ value: string }>) => {
        const value = event.target.value;
        if (value === '') {
            setCommitted(null);
        } else {
            setCommitted(Number.parseFloat(event.target.value));
        }
    };

    const handleActualChange = (event: React.ChangeEvent<{ value: string }>) => {
        const value = event.target.value;
        if (value === '') {
            setActual(null);
        } else {
            setActual(Number.parseFloat(event.target.value));
        }
    };

    const handleOpenDefectsLowestChange = (event: React.ChangeEvent<{ value: string }>) => {
        const value = event.target.value;
        if (value === '') {
            setOpenDefectsLowest(null);
        } else {
            setOpenDefectsLowest(Number.parseInt(event.target.value, 10));
        }
    };

    const handleOpenDefectsLowChange = (event: React.ChangeEvent<{ value: string }>) => {
        const value = event.target.value;
        if (value === '') {
            setOpenDefectsLow(null);
        } else {
            setOpenDefectsLow(Number.parseInt(event.target.value, 10));
        }
    };

    const handleOpenDefectsMediumChange = (event: React.ChangeEvent<{ value: string }>) => {
        const value = event.target.value;
        if (value === '') {
            setOpenDefectsMedium(null);
        } else {
            setOpenDefectsMedium(Number.parseInt(event.target.value, 10));
        }
    };

    const handleOpenDefectsHighChange = (event: React.ChangeEvent<{ value: string }>) => {
        const value = event.target.value;
        if (value === '') {
            setOpenDefectsHigh(null);
        } else {
            setOpenDefectsHigh(Number.parseInt(event.target.value, 10));
        }
    };

    const handleOpenDefectsUrgentChange = (event: React.ChangeEvent<{ value: string }>) => {
        const value = event.target.value;
        if (value === '') {
            setOpenDefectsUrgent(null);
        } else {
            setOpenDefectsUrgent(Number.parseInt(event.target.value, 10));
        }
    };

    const handleNewDefectsLowestChange = (event: React.ChangeEvent<{ value: string }>) => {
        const value = event.target.value;
        if (value === '') {
            setNewDefectsLowest(null);
        } else {
            setNewDefectsLowest(Number.parseInt(event.target.value, 10));
        }
    };

    const handleNewDefectsLowChange = (event: React.ChangeEvent<{ value: string }>) => {
        const value = event.target.value;
        if (value === '') {
            setNewDefectsLow(null);
        } else {
            setNewDefectsLow(Number.parseInt(event.target.value, 10));
        }
    };

    const handleNewDefectsMediumChange = (event: React.ChangeEvent<{ value: string }>) => {
        const value = event.target.value;
        if (value === '') {
            setNewDefectsMedium(null);
        } else {
            setNewDefectsMedium(Number.parseInt(event.target.value, 10));
        }
    };

    const handleNewDefectsHighChange = (event: React.ChangeEvent<{ value: string }>) => {
        const value = event.target.value;
        if (value === '') {
            setNewDefectsHigh(null);
        } else {
            setNewDefectsHigh(Number.parseInt(event.target.value, 10));
        }
    };

    const handleNewDefectsUrgentChange = (event: React.ChangeEvent<{ value: string }>) => {
        const value = event.target.value;
        if (value === '') {
            setNewDefectsUrgent(null);
        } else {
            setNewDefectsUrgent(Number.parseInt(event.target.value, 10));
        }
    };

    const handleClosedDefectsLowestChange = (event: React.ChangeEvent<{ value: string }>) => {
        const value = event.target.value;
        if (value === '') {
            setClosedDefectsLowest(null);
        } else {
            setClosedDefectsLowest(Number.parseInt(event.target.value, 10));
        }
    };

    const handleClosedDefectsLowChange = (event: React.ChangeEvent<{ value: string }>) => {
        const value = event.target.value;
        if (value === '') {
            setClosedDefectsLow(null);
        } else {
            setClosedDefectsLow(Number.parseInt(event.target.value, 10));
        }
    };

    const handleClosedDefectsMediumChange = (event: React.ChangeEvent<{ value: string }>) => {
        const value = event.target.value;
        if (value === '') {
            setClosedDefectsMedium(null);
        } else {
            setClosedDefectsMedium(Number.parseInt(event.target.value, 10));
        }
    };

    const handleClosedDefectsHighChange = (event: React.ChangeEvent<{ value: string }>) => {
        const value = event.target.value;
        if (value === '') {
            setClosedDefectsHigh(null);
        } else {
            setClosedDefectsHigh(Number.parseInt(event.target.value, 10));
        }
    };

    const handleClosedDefectsUrgentChange = (event: React.ChangeEvent<{ value: string }>) => {
        const value = event.target.value;
        if (value === '') {
            setClosedDefectsUrgent(null);
        } else {
            setClosedDefectsUrgent(Number.parseInt(event.target.value, 10));
        }
    };

    const handleTimeInStatusChange = (status: string, event: React.ChangeEvent<{ value: string }>) => {
        const value = event.target.value;
        const timeInStatusClone = Object.assign({}, timeInStatus);
        if (value === '') {
            timeInStatusClone[status] = '';
        } else {
            timeInStatusClone[status] = Number.parseFloat(event.target.value);
        }

        setTimeInStatus(timeInStatusClone);
    };

    const handleSubmit = () => {
        const timeInStatusValue: TimeInStatusSubmit = {};
        Object.keys(timeInStatus).forEach((key) => {
            timeInStatusValue[key] = timeInStatus[key] === '' ? 0 : (timeInStatus[key] as number);
        });

        const sprintViewModel: SprintViewModel = {
            name,
            startDate: new Date(parseISO(startDate)).toISOString(),
            endDate: new Date(parseISO(endDate)).toISOString(),
            goal,
            goalAchieved,
            rationale,
            trackGoal,
            velocity: {
                committed: committed as number,
                actual: actual as number,
            },
            openDefects: {
                lowest: openDefectsLowest as number,
                low: openDefectsLow as number,
                medium: openDefectsMedium as number,
                high: openDefectsHigh as number,
                urgent: openDefectsUrgent as number,
            },
            newDefectsThisSprint: {
                lowest: newDefectsLowest as number,
                low: newDefectsLow as number,
                medium: newDefectsMedium as number,
                high: newDefectsHigh as number,
                urgent: newDefectsUrgent as number,
            },
            closedDefectsThisSprint: {
                lowest: closedDefectsLowest as number,
                low: closedDefectsLow as number,
                medium: closedDefectsMedium as number,
                high: closedDefectsHigh as number,
                urgent: closedDefectsUrgent as number,
            },
            timeInStatus: timeInStatusValue,
        };

        if (props.sprint) {
            sprintViewModel.id = props.sprint.id;
        }

        props.onSubmit(sprintViewModel);
    };

    const valid = () => {
        let timeInStatusValid = true;
        Object.keys(timeInStatus).forEach((key) => {
            if (timeInStatus[key] === '') {
                timeInStatusValid = false;
            }
        });
        return name.length > 0 && startDate.length > 0 && endDate.length > 0 && trackGoal
            ? goal.length > 0 && (goalAchieved ? true : rationale.length > 0)
            : true &&
                  committed !== null &&
                  actual !== null &&
                  openDefectsLowest !== null &&
                  openDefectsLow !== null &&
                  openDefectsMedium !== null &&
                  openDefectsHigh !== null &&
                  openDefectsUrgent !== null &&
                  newDefectsLowest !== null &&
                  newDefectsLow !== null &&
                  newDefectsMedium !== null &&
                  newDefectsHigh !== null &&
                  newDefectsUrgent !== null &&
                  closedDefectsLowest !== null &&
                  closedDefectsLow !== null &&
                  closedDefectsMedium !== null &&
                  closedDefectsHigh !== null &&
                  closedDefectsUrgent !== null &&
                  timeInStatusValid;
    };

    const clearState = () => {
        // Cannot call this function from within useEffect, so we have to duplicate
        // the logic there too.  Can look into alternatives at some point...
        setName(props.sprint?.name || '');
        setStartDate(props.sprint?.startDate ? format(new Date(props.sprint.startDate), 'yyyy-MM-dd') : '');
        setEndDate(props.sprint?.endDate ? format(new Date(props.sprint.endDate), 'yyyy-MM-dd') : '');
        setGoal(props.sprint?.goal?.description || '');
        setGoalAchieved(props.sprint?.goal?.achieved || false);
        setRationale(props.sprint?.goal?.rationale || '');
        setTrackGoal(props.sprint?.goal ? props.sprint.goal.trackGoal : true);
        setCommitted(props.sprint?.metrics.productivity.velocity.committed || 0);
        setActual(props.sprint?.metrics.productivity.velocity.actual || 0);
        setOpenDefectsLowest(props.sprint?.metrics.quality.openDefects.lowest || 0);
        setOpenDefectsLow(props.sprint?.metrics.quality.openDefects.low || 0);
        setOpenDefectsMedium(props.sprint?.metrics.quality.openDefects.medium || 0);
        setOpenDefectsHigh(props.sprint?.metrics.quality.openDefects.high || 0);
        setOpenDefectsUrgent(props.sprint?.metrics.quality.openDefects.urgent || 0);
        setNewDefectsLowest(props.sprint?.metrics.quality.newDefectsThisSprint.lowest || 0);
        setNewDefectsLow(props.sprint?.metrics.quality.newDefectsThisSprint.low || 0);
        setNewDefectsMedium(props.sprint?.metrics.quality.newDefectsThisSprint.medium || 0);
        setNewDefectsHigh(props.sprint?.metrics.quality.newDefectsThisSprint.high || 0);
        setNewDefectsUrgent(props.sprint?.metrics.quality.newDefectsThisSprint.urgent || 0);
        setClosedDefectsLowest(props.sprint?.metrics.quality.closedDefectsThisSprint.lowest || 0);
        setClosedDefectsLow(props.sprint?.metrics.quality.closedDefectsThisSprint.low || 0);
        setClosedDefectsMedium(props.sprint?.metrics.quality.closedDefectsThisSprint.medium || 0);
        setClosedDefectsHigh(props.sprint?.metrics.quality.closedDefectsThisSprint.high || 0);
        setClosedDefectsUrgent(props.sprint?.metrics.quality.closedDefectsThisSprint.urgent || 0);

        const newTimeInStatus: TimeInStatus = {};
        if (props.team) {
            props.team.statuses.forEach((status) => {
                newTimeInStatus[status] = 0;
            });
        }
        setDefaultTimeInStatus(props.sprint?.metrics.predictability.timeInStatus || newTimeInStatus);
        setTimeInStatus(props.sprint?.metrics.predictability.timeInStatus || newTimeInStatus);
    };

    const hideDialog = () => {
        clearState();
        props.onClose();
    };

    const now = new Date().valueOf();
    const statusFields = props.team?.statuses.map((status) => {
        return (
            <TextField
                key={`status-${status}-textfield`}
                sx={{
                    minWidth: 120,
                    marginRight: theme.spacing(2),
                }}
                required
                id={`status-${status}-textfield`}
                label={`${status}`}
                value={timeInStatus[status]}
                onChange={(event) => {
                    handleTimeInStatusChange(status, event);
                }}
                type="number"
                variant="standard"
            />
        );
    });

    useEffect(() => {
        setName(props.sprint?.name || '');
        setStartDate(props.sprint?.startDate ? format(new Date(props.sprint.startDate), 'yyyy-MM-dd') : '');
        setEndDate(props.sprint?.endDate ? format(new Date(props.sprint.endDate), 'yyyy-MM-dd') : '');
        setGoal(props.sprint?.goal?.description || '');
        setGoalAchieved(props.sprint?.goal?.achieved || false);
        setRationale(props.sprint?.goal?.rationale || '');
        setTrackGoal(props.sprint?.goal ? props.sprint.goal.trackGoal : true);
        setCommitted(props.sprint?.metrics.productivity.velocity.committed || 0);
        setActual(props.sprint?.metrics.productivity.velocity.actual || 0);
        setOpenDefectsLowest(props.sprint?.metrics.quality.openDefects.lowest || 0);
        setOpenDefectsLow(props.sprint?.metrics.quality.openDefects.low || 0);
        setOpenDefectsMedium(props.sprint?.metrics.quality.openDefects.medium || 0);
        setOpenDefectsHigh(props.sprint?.metrics.quality.openDefects.high || 0);
        setOpenDefectsUrgent(props.sprint?.metrics.quality.openDefects.urgent || 0);
        setNewDefectsLowest(props.sprint?.metrics.quality.newDefectsThisSprint.lowest || 0);
        setNewDefectsLow(props.sprint?.metrics.quality.newDefectsThisSprint.low || 0);
        setNewDefectsMedium(props.sprint?.metrics.quality.newDefectsThisSprint.medium || 0);
        setNewDefectsHigh(props.sprint?.metrics.quality.newDefectsThisSprint.high || 0);
        setNewDefectsUrgent(props.sprint?.metrics.quality.newDefectsThisSprint.urgent || 0);
        setClosedDefectsLowest(props.sprint?.metrics.quality.closedDefectsThisSprint.lowest || 0);
        setClosedDefectsLow(props.sprint?.metrics.quality.closedDefectsThisSprint.low || 0);
        setClosedDefectsMedium(props.sprint?.metrics.quality.closedDefectsThisSprint.medium || 0);
        setClosedDefectsHigh(props.sprint?.metrics.quality.closedDefectsThisSprint.high || 0);
        setClosedDefectsUrgent(props.sprint?.metrics.quality.closedDefectsThisSprint.urgent || 0);

        const newTimeInStatus: TimeInStatus = {};
        if (props.team) {
            props.team.statuses.forEach((status) => {
                newTimeInStatus[status] = 0;
            });
        }
        setDefaultTimeInStatus(props.sprint?.metrics.predictability.timeInStatus || newTimeInStatus);
        setTimeInStatus(props.sprint?.metrics.predictability.timeInStatus || newTimeInStatus);
    }, [props.team, props.sprint]);

    return (
        <Dialog
            fullScreen={fullScreen}
            open={props.open}
            onClose={hideDialog}
            maxWidth="md"
            fullWidth={true}
            scroll="paper"
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onBackdropClick={() => {}}
            disableEscapeKeyDown
        >
            <DialogTitle id="sprint-dialog-title">{props.sprint ? 'Update Sprint' : 'Add Sprint'}</DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Card
                        sx={{
                            padding: theme.spacing(2),
                            marginBottom: theme.spacing(2),
                        }}
                        variant="outlined"
                    >
                        <Typography>General Info</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <TextField
                                sx={{
                                    minWidth: 120,
                                    marginBottom: theme.spacing(2),
                                }}
                                required
                                id={`sprint-name-textfield-${now}`}
                                label="Sprint Name"
                                value={name}
                                onChange={handleNameChange}
                                variant="standard"
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                }}
                            >
                                <TextField
                                    id="sprint-start-date"
                                    label="Start Date"
                                    type="date"
                                    sx={{
                                        minWidth: 120,
                                        marginRight: theme.spacing(2),
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={startDate}
                                    onChange={handleStartDateChange}
                                    required
                                    variant="standard"
                                />
                                <TextField
                                    id="sprint-end-date"
                                    label="End Date"
                                    type="date"
                                    sx={{
                                        minWidth: 120,
                                        marginRight: theme.spacing(2),
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={endDate}
                                    onChange={handleEndDateChange}
                                    required
                                    variant="standard"
                                />
                            </Box>
                        </Box>
                    </Card>
                    <Card
                        sx={{
                            padding: theme.spacing(2),
                            marginBottom: theme.spacing(2),
                        }}
                        variant="outlined"
                    >
                        <Typography>Goal Info</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={`use-sprint-goal-checkbox-${now}`}
                                        checked={trackGoal}
                                        onChange={handleTrackGoalChange}
                                    />
                                }
                                label="Track Sprint Goal?"
                            />
                            <TextField
                                sx={{
                                    minWidth: 120,
                                    marginBottom: theme.spacing(2),
                                }}
                                required
                                id={`sprint-goal-description-textfield-${now}`}
                                label="Sprint Goal"
                                value={goal}
                                onChange={handleGoalChange}
                                disabled={!trackGoal}
                                variant="standard"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={`sprint-goal-achieved-checkbox-${now}`}
                                        checked={goalAchieved}
                                        onChange={handleGoalAchievedChange}
                                        disabled={!trackGoal}
                                    />
                                }
                                label="Goal Achieved"
                            />
                            <TextField
                                sx={{
                                    minWidth: 120,
                                    marginBottom: theme.spacing(2),
                                }}
                                required={goalAchieved === false}
                                id={`sprint-goal-rationale-textfield-${now}`}
                                label={`Why wasn't the goal achieved?`}
                                value={rationale}
                                onChange={handleRationaleChange}
                                disabled={goalAchieved || !trackGoal}
                                variant="standard"
                            />
                        </Box>
                    </Card>
                    <Card
                        sx={{
                            padding: theme.spacing(2),
                            marginBottom: theme.spacing(2),
                        }}
                        variant="outlined"
                    >
                        <Typography>Velocity</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <TextField
                                sx={{
                                    minWidth: 120,
                                    marginRight: theme.spacing(2),
                                }}
                                required
                                id={`sprint-velocity-committed-textfield-${now}`}
                                label="Committed"
                                value={committed}
                                onChange={handleCommittedChange}
                                type="number"
                                variant="standard"
                            />
                            <TextField
                                sx={{
                                    minWidth: 120,
                                    marginRight: theme.spacing(2),
                                }}
                                required
                                id={`sprint-velocity-actual-textfield-${now}`}
                                label="Actual"
                                value={actual}
                                onChange={handleActualChange}
                                type="number"
                                variant="standard"
                            />
                        </Box>
                    </Card>
                    <Card
                        sx={{
                            padding: theme.spacing(2),
                            marginBottom: theme.spacing(2),
                        }}
                        variant="outlined"
                    >
                        <Typography>Open Defects</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <TextField
                                sx={{
                                    minWidth: 120,
                                    marginRight: theme.spacing(2),
                                }}
                                required
                                id={`sprint-quality-openDefectsLowest-textfield-${now}`}
                                label="Lowest"
                                value={openDefectsLowest}
                                onChange={handleOpenDefectsLowestChange}
                                type="number"
                                variant="standard"
                            />
                            <TextField
                                sx={{
                                    minWidth: 120,
                                    marginRight: theme.spacing(2),
                                }}
                                required
                                id={`sprint-quality-openDefectsLow-textfield-${now}`}
                                label="Low"
                                value={openDefectsLow}
                                onChange={handleOpenDefectsLowChange}
                                type="number"
                                variant="standard"
                            />
                            <TextField
                                sx={{
                                    minWidth: 120,
                                    marginRight: theme.spacing(2),
                                }}
                                required
                                id={`sprint-quality-openDefectsMedium-textfield-${now}`}
                                label="Medium"
                                value={openDefectsMedium}
                                onChange={handleOpenDefectsMediumChange}
                                type="number"
                                variant="standard"
                            />
                            <TextField
                                sx={{
                                    minWidth: 120,
                                    marginRight: theme.spacing(2),
                                }}
                                required
                                id={`sprint-quality-openDefectsHigh-textfield-${now}`}
                                label="High"
                                value={openDefectsHigh}
                                onChange={handleOpenDefectsHighChange}
                                type="number"
                                variant="standard"
                            />
                            <TextField
                                sx={{
                                    minWidth: 120,
                                    marginRight: theme.spacing(2),
                                }}
                                required
                                id={`sprint-quality-openDefectsUrgent-textfield-${now}`}
                                label="Urgent"
                                value={openDefectsUrgent}
                                onChange={handleOpenDefectsUrgentChange}
                                type="number"
                                variant="standard"
                            />
                        </Box>
                    </Card>
                    <Card
                        sx={{
                            padding: theme.spacing(2),
                            marginBottom: theme.spacing(2),
                        }}
                        variant="outlined"
                    >
                        <Typography>New Defects This Sprint</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <TextField
                                sx={{
                                    minWidth: 120,
                                    marginRight: theme.spacing(2),
                                }}
                                required
                                id={`sprint-quality-newDefectsLowest-textfield-${now}`}
                                label="Lowest"
                                value={newDefectsLowest}
                                onChange={handleNewDefectsLowestChange}
                                type="number"
                                variant="standard"
                            />
                            <TextField
                                sx={{
                                    minWidth: 120,
                                    marginRight: theme.spacing(2),
                                }}
                                required
                                id={`sprint-quality-newDefectsLow-textfield-${now}`}
                                label="Low"
                                value={newDefectsLow}
                                onChange={handleNewDefectsLowChange}
                                type="number"
                                variant="standard"
                            />
                            <TextField
                                sx={{
                                    minWidth: 120,
                                    marginRight: theme.spacing(2),
                                }}
                                required
                                id={`sprint-quality-newDefectsMedium-textfield-${now}`}
                                label="Medium"
                                value={newDefectsMedium}
                                onChange={handleNewDefectsMediumChange}
                                type="number"
                                variant="standard"
                            />
                            <TextField
                                sx={{
                                    minWidth: 120,
                                    marginRight: theme.spacing(2),
                                }}
                                required
                                id={`sprint-quality-newDefectsHigh-textfield-${now}`}
                                label="High"
                                value={newDefectsHigh}
                                onChange={handleNewDefectsHighChange}
                                type="number"
                                variant="standard"
                            />
                            <TextField
                                sx={{
                                    minWidth: 120,
                                    marginRight: theme.spacing(2),
                                }}
                                required
                                id={`sprint-quality-newDefectsUrgent-textfield-${now}`}
                                label="Urgent"
                                value={newDefectsUrgent}
                                onChange={handleNewDefectsUrgentChange}
                                type="number"
                                variant="standard"
                            />
                        </Box>
                    </Card>
                    <Card
                        sx={{
                            padding: theme.spacing(2),
                            marginBottom: theme.spacing(2),
                        }}
                        variant="outlined"
                    >
                        <Typography>Closed Defects This Sprint</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <TextField
                                sx={{
                                    minWidth: 120,
                                    marginRight: theme.spacing(2),
                                }}
                                required
                                id={`sprint-quality-closedDefectsLowest-textfield-${now}`}
                                label="Lowest"
                                value={closedDefectsLowest}
                                onChange={handleClosedDefectsLowestChange}
                                type="number"
                                variant="standard"
                            />
                            <TextField
                                sx={{
                                    minWidth: 120,
                                    marginRight: theme.spacing(2),
                                }}
                                required
                                id={`sprint-quality-closedDefectsLow-textfield-${now}`}
                                label="Low"
                                value={closedDefectsLow}
                                onChange={handleClosedDefectsLowChange}
                                type="number"
                                variant="standard"
                            />
                            <TextField
                                sx={{
                                    minWidth: 120,
                                    marginRight: theme.spacing(2),
                                }}
                                required
                                id={`sprint-quality-closedDefectsMedium-textfield-${now}`}
                                label="Medium"
                                value={closedDefectsMedium}
                                onChange={handleClosedDefectsMediumChange}
                                type="number"
                                variant="standard"
                            />
                            <TextField
                                sx={{
                                    minWidth: 120,
                                    marginRight: theme.spacing(2),
                                }}
                                required
                                id={`sprint-quality-closedDefectsHigh-textfield-${now}`}
                                label="High"
                                value={closedDefectsHigh}
                                onChange={handleClosedDefectsHighChange}
                                type="number"
                                variant="standard"
                            />
                            <TextField
                                sx={{
                                    minWidth: 120,
                                    marginRight: theme.spacing(2),
                                }}
                                required
                                id={`sprint-quality-closedDefectsUrgent-textfield-${now}`}
                                label="Urgent"
                                value={closedDefectsUrgent}
                                onChange={handleClosedDefectsUrgentChange}
                                type="number"
                                variant="standard"
                            />
                        </Box>
                    </Card>
                    <Card
                        sx={{
                            padding: theme.spacing(2),
                            marginBottom: theme.spacing(2),
                        }}
                        variant="outlined"
                    >
                        <Typography>Time In Status</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                            }}
                        >
                            {statusFields}
                        </Box>
                    </Card>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={hideDialog} color="secondary" variant="contained">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary" variant="contained" disabled={valid() === false}>
                    {props.sprint ? 'Update' : 'Add'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SprintDialog;
