import { Nullable, Team } from '@aviture/interfaces';
import { State } from '../../store/State';
import { Teams } from './Teams';

export const FirstTeam = (state: State): Nullable<Team> => {
    const teams = Teams(state);
    if (teams && teams.length) {
        return teams[0];
    }

    return null;
};
