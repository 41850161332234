import React from 'react';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import AppSectionNavigator from './AppSectionNavigator';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Nullable, Team, User } from '@aviture/interfaces';
import { Auth } from 'aws-amplify';
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import ToggleButton from '@mui/material/ToggleButton';
import CheckBoxOutline from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBox from '@mui/icons-material/CheckBox';
import Typography from '@mui/material/Typography';

interface IProps {
    currentTeam: Nullable<Team>;
    teams: Nullable<Team[]>;
    user: Nullable<User>;
    enabledPages: string[];
    enabledSections: string[];
    enabledActions: string[];
    activeSection: Nullable<string>;
    onShowInactive: (newValue: boolean) => unknown;
}

const PrimaryNavigation: React.FC<IProps> = (props) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [teamAnchorEl, setTeamAnchorEl] = React.useState(null);
    const [showInactive, setShowInactive] = React.useState(false);

    const handleProfileClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleProfileClose = () => {
        setAnchorEl(null);
    };

    const handleTeamClick = (event: any) => {
        setTeamAnchorEl(event.currentTarget);
    };

    const handleTeamClose = () => {
        setTeamAnchorEl(null);
    };

    const handleTeamSelected = () => {
        handleTeamClose();
    };

    const toggleShowInactive = () => {
        props.onShowInactive(!showInactive);
        setShowInactive(!showInactive);
    };

    const logout = () => {
        try {
            Auth.signOut();
            handleProfileClose();
            navigate('/');
        } catch (error) {
            console.log(`error signing out: ${error}`);
        }
    };

    const { user, teams, currentTeam } = props;
    let teamsMenuItems: JSX.Element[] = [];
    if (teams !== null && props.currentTeam !== null) {
        const currentTeamId = props.currentTeam.id;
        teamsMenuItems = teams.map((team) => {
            const toLink =
                location.pathname.indexOf('/teams') === 0
                    ? location.pathname.replace(`/${currentTeamId}/`, `/${team.id}/`)
                    : `/teams/${team.id}/${props.enabledPages[0]}`;
            return (
                <MenuItem
                    onClick={() => {
                        handleTeamSelected();
                    }}
                    key={team.id}
                >
                    <NavLink to={toLink}>
                        {team.name} ({team.program})
                    </NavLink>
                </MenuItem>
            );
        });
    }

    return (
        <AppBar position="static">
            <Toolbar disableGutters={true}>
                <Link
                    to="/"
                    style={{
                        height: '64px',
                    }}
                >
                    <img src="/Aviture-boxed-logo.png" alt="logo" height="64px" />
                </Link>
                <AppSectionNavigator
                    sx={{
                        marginLeft: theme.spacing(2),
                    }}
                    enabledSections={props.enabledSections}
                    active={props.activeSection}
                />
                <Box
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    {currentTeam ? (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {props.enabledActions.indexOf('show-inactive-teams') > -1 ? (
                                <ToggleButton
                                    value="check"
                                    selected={showInactive}
                                    onChange={toggleShowInactive}
                                    sx={{
                                        marginRight: theme.spacing(2),
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {showInactive ? (
                                        <CheckBox
                                            sx={{
                                                color: theme.palette.common.white,
                                            }}
                                        />
                                    ) : (
                                        <CheckBoxOutline sx={{ color: theme.palette.common.white }} />
                                    )}

                                    <Typography
                                        variant="caption"
                                        sx={{
                                            color: theme.palette.common.white,
                                            marginLeft: theme.spacing(1),
                                        }}
                                    >
                                        Show Inactive
                                    </Typography>
                                </ToggleButton>
                            ) : undefined}

                            <Chip
                                color="secondary"
                                clickable
                                label={`${currentTeam.name} (${currentTeam.program})`}
                                onClick={handleTeamClick}
                                deleteIcon={<ExpandMoreIcon />}
                                onDelete={handleTeamClick}
                            />
                            <Menu
                                id="team-menu"
                                anchorEl={teamAnchorEl}
                                keepMounted
                                open={Boolean(teamAnchorEl)}
                                onClose={handleTeamClose}
                            >
                                {teamsMenuItems}
                            </Menu>
                        </Box>
                    ) : (
                        ''
                    )}
                </Box>
                <Box
                    sx={{
                        display: 'none',
                        [theme.breakpoints.up('sm')]: {
                            display: 'flex',
                            paddingRight: theme.spacing(2),
                        },
                    }}
                >
                    {user !== null ? (
                        <React.Fragment>
                            <IconButton
                                edge="end"
                                aria-label="account of current user"
                                color="primary"
                                onClick={handleProfileClick}
                            >
                                <Avatar
                                    sx={{
                                        width: theme.spacing(4),
                                        height: theme.spacing(4),
                                    }}
                                    src={user.avatarUrl}
                                />
                            </IconButton>
                            <Menu
                                id="profile-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleProfileClose}
                            >
                                <MenuItem onClick={handleProfileClose}>
                                    <Link to="/profile">Profile</Link>
                                </MenuItem>
                                <MenuItem onClick={logout}>Logout</MenuItem>
                            </Menu>
                        </React.Fragment>
                    ) : (
                        <div></div>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default PrimaryNavigation;
