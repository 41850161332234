import { Store } from 'use-global-hook';
import { State } from '../store/State';
import { Metadata } from '@aviture/interfaces';
import { API } from 'aws-amplify';

export const GetMetadata = async (store: Store<State, unknown>): Promise<void> => {
    if (store.state.metadata === null) {
        const init = {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
        };

        try {
            const responseBody = await API.get('JiraMetricsApi', '/app/getMetadata', init);
            const metadata: Metadata[] = responseBody.metadata as Metadata[];

            store.setState({
                ...store.state,
                metadata,
            });
        } catch (err) {
            console.log(`An error occurred ${JSON.stringify(err)}`);
        }
    }
};
