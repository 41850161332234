import React from 'react';
import Box from '@mui/material/Box';

const LoadingIndicator: React.FC = () => {
    return (
        <Box className="lds-facebook">
            <Box></Box>
            <Box></Box>
            <Box></Box>
        </Box>
    );
};

export default LoadingIndicator;
