import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

interface Props {
    subtitle?: boolean;
}

const SingleValueChartLoader: React.FC<Props> = (props) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Typography variant="h1">
                <Skeleton
                    variant="text"
                    width={250}
                    sx={{
                        marginTop: theme.spacing(2),
                    }}
                    animation="wave"
                />
            </Typography>
            {props.subtitle ? (
                <Typography variant="h5">
                    <Skeleton
                        variant="text"
                        width={175}
                        sx={{
                            marginTop: theme.spacing(2),
                        }}
                        animation="wave"
                    />
                </Typography>
            ) : null}
        </Box>
    );
};

export default SingleValueChartLoader;
