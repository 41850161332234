import React, { useEffect, useState } from 'react';
import useGlobal from '../../store';
import { AllEmployees } from '../../selectors/employee/AllEmployees';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import EmployeesOptionMenu from '../../components/EmployeesOptionsMenu';
import EmptyView from '../../components/EmptyView';
import { CurrentUserIsAdmin } from '../../selectors/user/CurrentUserIsAdmin';
import { useTheme } from '@mui/material/styles';
import PageContainer from './PageContainer';
import Paper from '@mui/material/Paper';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

const EmployeesAdminPage: React.FC = () => {
    const theme = useTheme();
    const [state, actions] = useGlobal();
    const [ready, setReady] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        const loadData = async () => {
            setReady(false);
            await actions.GetAllEmployees();
            setReady(true);
        };

        loadData();
    }, [actions, state.employees, state.alwaysNull]);

    const currentUserIsAdmin = CurrentUserIsAdmin(state);
    const employees = AllEmployees(state);

    const handleAddEmployee = () => {
        navigate('/admin/employees/create');
    };

    return (
        <PageContainer activePage="employees">
            <Box>
                {ready ? (
                    <Box sx={{
                        marginBottom: theme.spacing(2),
                        paddingBottom: theme.spacing(2),
                    }}>
                        {currentUserIsAdmin ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    width: '100%',
                                    marginBottom: theme.spacing(2),
                                }}
                            >
                                <Button color="primary" variant="contained" onClick={handleAddEmployee}>
                                    Add Employee
                                </Button>
                            </Box>
                        ) : null}
                        {employees !== null && employees.length ? (
                            <TableContainer component={Paper}>
                                <Table aria-label="all-employees-table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>First Name</TableCell>
                                            <TableCell>Last Name</TableCell>
                                            <TableCell width={64}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {employees.map((employee, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <Typography>{employee.firstName}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>{employee.lastName}</Typography>
                                                </TableCell>
                                                <TableCell
                                                    width={64}
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-end',
                                                    }}
                                                >
                                                    <EmployeesOptionMenu employee={employee} />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <EmptyView
                                primaryText="No employees found"
                                secondaryText="Click on Add Employee to create the first one."
                                icon={<PersonOutlineIcon />}
                            />
                        )}
                    </Box>
                ) : (
                    <Box>Loading...</Box>
                )}
            </Box>
        </PageContainer>
    );
};

export default EmployeesAdminPage;
