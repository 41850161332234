import React, { useEffect } from 'react';
import useGlobal from '../../store';
import { useNavigate } from 'react-router-dom';
import { CurrentTeamId } from '../../selectors/team/CurrentTeamId';

interface IProps {
    enabledPages: string[];
}

const TeamsPage: React.FC<IProps> = (props) => {
    const [state, actions] = useGlobal();
    const navigate = useNavigate();

    useEffect(() => {
        const loadData = async () => {
            const currentTeamId = CurrentTeamId(state);

            if (currentTeamId) {
                actions.SetCurrentTeam(state.currentTeamId);
                navigate(`/teams/${state.currentTeamId}/${props.enabledPages[0]}`);
            } else {
                actions.SetFirstTeamAsCurrent();
            }
            actions.SetDashboardReady(true);
        };

        loadData();
    }, [actions, state.alwaysNull, state.currentTeamId, props, navigate]);

    return <div>Loading...</div>;
};

export default TeamsPage;
