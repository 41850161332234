import { Sprint } from '@aviture/interfaces';

export const GetAllYearsForSprintEndDates = (sprints: Array<Sprint>): Array<number> => {
    const years: Array<number> = [];

    sprints.forEach((sprint) => {
        const endDateYear = new Date(sprint.endDate).getFullYear();
        if (years.indexOf(endDateYear) === -1) {
            years.push(endDateYear);
        }
    });

    return years.sort();
};
