import { Store } from 'use-global-hook';
import { State } from '../store/State';
import { SpecificTeam } from '../selectors/team/SpecificTeam';

export const SetCurrentTeam = (store: Store<State, unknown>, teamId: string): void => {
    const team = SpecificTeam(store.state, teamId);
    if (team !== null && store.state.currentTeamId !== teamId) {
        store.setState({
            ...store.state,
            currentTeamId: teamId,
            currentSprintId: null,
        });
    }
};
