import { Store } from 'use-global-hook';
import { State } from '../store/State';
import { API } from 'aws-amplify';
import { ClearAlert } from './ClearAlert';
import { CurrentTeam } from '../selectors/team/CurrentTeam';
import { FireAlert } from './FireAlert';
import { SetAppLoading } from './SetAppLoading';
import { UpdateTeamData } from './UpdateTeamData';

export const DeleteSprint = async (store: Store<State, unknown>, sprintId: string): Promise<void> => {
    const currentTeam = CurrentTeam(store.state);

    if (currentTeam !== null && sprintId !== null) {
        if (currentTeam.sprints) {
            currentTeam.sprints = currentTeam.sprints.filter((sprint) => {
                return sprint.id !== sprintId;
            });

            const init = {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                body: currentTeam,
            };
    
            SetAppLoading(store, true);
            const response = await API.put('JiraMetricsApi', '/teams/updateTeam', init);
            UpdateTeamData(store, response.team);
            SetAppLoading(store, false);
            FireAlert(store, 'Sprint deleted successfully.', 'success');
            setTimeout(() => {
                ClearAlert(store);
            }, 3000);
        }
    }
};
