import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import { SxProps, Theme, useTheme } from '@mui/material/styles';

const SMALL = '150px';
const MEDIUM = '350px';
const LARGE = '400px';

interface IProps {
    loading: boolean;
    children: unknown;
    size: 'small' | 'medium' | 'large';
    flush?: boolean;
    header?: string;
    loader?: JSX.Element;
}

const ChartContainer: React.FC<IProps> = (props) => {
    const theme = useTheme();
    const cardCss = (props: IProps): SxProps<Theme> => {
        let height;
        switch (props.size) {
            case 'small':
                height = SMALL;
                break;
            case 'medium':
                height = MEDIUM;
                break;
            default:
                height = LARGE;
                break;
        }
        return {
            height,
            marginBottom: props.flush ? 0 : theme.spacing(3),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        };
    };

    return (
        <Card>
            <CardHeader title={props.header}/>
            <CardContent sx={cardCss(props)}>
                {props.loading ? props.loader ? props.loader : <CircularProgress size={120} /> : props.children}
            </CardContent>
        </Card>
    );
};

export default ChartContainer;
