import { Store } from 'use-global-hook';
import { State } from '../store/State';
import { API } from 'aws-amplify';
import { CurrentTeam } from '../selectors/team/CurrentTeam';
import { GetAllTeams } from './GetAllTeams';
import { SetAppLoading } from './SetAppLoading';
import { ShowInactiveTeams } from '../selectors/ui/ShowInactiveTeams';

export const RemoveTeamMember = async (store: Store<State, unknown>, employeeId: string): Promise<void> => {
    const currentTeam = CurrentTeam(store.state);

    if (currentTeam !== null && employeeId !== null) {
        if (currentTeam.people) {
            currentTeam.people = currentTeam.people.filter((teamMember) => {
                return teamMember.employeeId !== employeeId;
            });
        }

        const init = {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            body: currentTeam,
        };

        SetAppLoading(store, true);
        await API.put('JiraMetricsApi', '/teams/updateTeam', init);
        await GetAllTeams(store, ShowInactiveTeams(store.state));
        SetAppLoading(store, false);
    }
};
