import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import DeactivateTeamDialog from './DeactivateTeamDialog';
import { Team } from '@aviture/interfaces';

interface Props {
    onClick: () => void;
    team: Team;
}

const DeactivateTeamMenuItem: React.FC<Props> = (props) => {
    const [showDialog, setShowDialog] = useState<boolean>(false);

    const showDeactivateTeamDialog = () => {
        props.onClick();
        setShowDialog(true);
    };

    const hideDeactivateTeamDialog = () => {
        setShowDialog(false);
    };

    return (
        <React.Fragment>
            <React.Fragment>
                <MenuItem onClick={showDeactivateTeamDialog}>Dectivate</MenuItem>
                <DeactivateTeamDialog
                    teamId={props.team.id as string}
                    teamName={props.team.name}
                    onClose={hideDeactivateTeamDialog}
                    open={showDialog}
                />
            </React.Fragment>
        </React.Fragment>
    );
};

export default DeactivateTeamMenuItem;
