import { State } from '../../store/State';
import { Nullable } from '@aviture/interfaces';
import { FilteredSprints } from '../sprint/FilteredSprints';

interface SprintVelocity {
    name: string;
    committed: number;
    actual: number;
}
export const SprintVelocities = (state: State): Nullable<SprintVelocity[]> => {
    const sprints = FilteredSprints(state);

    if (sprints && sprints.length) {
        return sprints.map((sprint) => {
            return {
                name: sprint.name,
                committed: sprint.metrics.productivity.velocity.committed,
                actual: sprint.metrics.productivity.velocity.actual,
            };
        });
    }

    return null;
};
